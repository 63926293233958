import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useIntl } from "react-intl";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { TIPO_RELATORIO, LABELS_TIPO_RELATORIO } from "../../../../_common/constantes";
import { AjaxBlackout } from "../../../../_common";

function ModalExportar({ ids = [], situacao, callback = () => null, filtros }) {
  const intl = useIntl();

  function exportar({ tipos }) {
    AjaxBlackout.Show();
    axios
      .post("/api/pipeline/lead/exportar", { situacao, tiposRelatorio: tipos, ids: ids.length ? ids : null, filtros })
      .then(callback)
      .catch(callback)
      .finally(() => AjaxBlackout.Hide());
  }

  const handleSelectAll = (values, setFieldValue) => {
    const allOptions = Object.values(TIPO_RELATORIO);
    setFieldValue("tipos", values.tipos.length !== allOptions.length ? allOptions : []);
  };

  return (
    <Formik
      initialValues={{ tipos: [] }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        if (values.tipos.length === 0) {
          setErrors({ tipos: intl.formatMessage({ defaultMessage: "Campo obrigatório *" }) });
          setSubmitting(false);
        } else {
          exportar(values);
        }
      }}
      onReset={() => callback(false)}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <DialogTitle>{intl.formatMessage({ defaultMessage: "Exportar Leads" })}</DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage:
                  "Selecione as informações que você deseja exportar ou alterar em massa, dos leads escolhidos. Assim que disponível você receberá os arquivos em seu e-mail.",
              })}
            </DialogContentText>
            <FormControl error={Boolean(touched.tipos && errors.tipos)}>
              <InputLabel>{intl.formatMessage({ defaultMessage: "Selecione o tipo de exportação" })}</InputLabel>
              <Select
                multiple
                value={values.tipos}
                onChange={event => {
                  const { value } = event.target;
                  if (value.includes("all")) {
                    handleSelectAll(values, setFieldValue);
                  } else {
                    setFieldValue("tipos", value);
                  }
                }}
                renderValue={selected => {
                  if (selected.length === Object.values(TIPO_RELATORIO).length) {
                    return intl.formatMessage({ defaultMessage: "Todos" });
                  }
                  return selected
                    .map(tipo => {
                      const label = LABELS_TIPO_RELATORIO[tipo];
                      return label ? intl.formatMessage(label) : tipo;
                    })
                    .join(", ");
                }}
              >
                <MenuItem value="all">
                  <Checkbox checked={values.tipos.length === Object.values(TIPO_RELATORIO).length} />
                  {intl.formatMessage({ defaultMessage: "Todos" })}
                </MenuItem>
                {Object.keys(TIPO_RELATORIO)
                  .map(tipo => TIPO_RELATORIO[tipo])
                  .sort((a, b) => {
                    const labelA = intl.formatMessage(LABELS_TIPO_RELATORIO[a]);
                    const labelB = intl.formatMessage(LABELS_TIPO_RELATORIO[b]);
                    return labelA.localeCompare(labelB);
                  })
                  .map(tipoRelatorio => (
                    <MenuItem key={tipoRelatorio} value={tipoRelatorio}>
                      <Checkbox checked={values.tipos.includes(tipoRelatorio)} />
                      {intl.formatMessage(LABELS_TIPO_RELATORIO[tipoRelatorio])}
                    </MenuItem>
                  ))}
              </Select>
              {touched.tipos && errors.tipos && <FormHelperText>{errors.tipos}</FormHelperText>}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="reset">{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button type="submit" color="primary">
              {intl.formatMessage({ defaultMessage: "Exportar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

ModalExportar.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  callback: PropTypes.func,
  filtros: PropTypes.object,
  situacao: PropTypes.number,
};

export default ModalExportar;
