import React from "react";
import PropTypes from "prop-types";
import RotateLeft from "@material-ui/icons/RotateLeft";
import { useIntl } from "react-intl";

import useDesfazerPularEtapas from "./useDesfazerPularEtapas";
import { ActionButton } from "../../../../components/ActionButton";
import PERMISSIONS from "../../../../_common/permissions";
import { TIPO_HISTORICO_CONVERSAO } from "../../../../_common/constantes";

DesfazerPularEtapas.propTypes = { lead: PropTypes.object, leadId: PropTypes.number, callback: PropTypes.func };
function DesfazerPularEtapas(props) {
  const intl = useIntl();
  const [lead, handleClick] = useDesfazerPularEtapas(props);

  // botão só visível pra leads cujo último movimento foi um pulo entre etapas
  if (
    ![TIPO_HISTORICO_CONVERSAO.PULARETAPA, TIPO_HISTORICO_CONVERSAO.PULARETAPASEMLOTE].includes(lead?.conversao?.tipo)
  )
    return null;

  const { callback, ...buttonProps } = props;

  return (
    <ActionButton
      onClick={handleClick}
      icon={<RotateLeft />}
      claim={PERMISSIONS.PULAR_ETAPAS}
      leads={lead}
      tooltip={intl.formatMessage({ defaultMessage: "Desfazer pular etapas" })}
      label={intl.formatMessage({ defaultMessage: "Desfazer pular etapas" })}
      {...buttonProps}
    />
  );
}

export default DesfazerPularEtapas;
