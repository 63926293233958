import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import StyleIcon from "@material-ui/icons/Style";
import { useIntl } from "react-intl";
import { Tooltip, withStyles } from "@material-ui/core";
import { Flex } from "../../../../_common";

BotaoConsultar.propTypes = {
  onClick: PropTypes.func,
};

export function BotaoConsultar({ classes, onClick }) {
  const intl = useIntl();
  return (
    <Tooltip title={intl.formatMessage({ defaultMessage: "Consulte com o Searching" })}>
      <Button color="primary" onClick={onClick}>
        <StyleIcon className={classes.icone} />
        <Flex className={classes.texto}>{intl.formatMessage({ defaultMessage: "Consultar" })}</Flex>
      </Button>
    </Tooltip>
  );
}

export default withStyles({
  icone: { marginBottom: 4 },
  texto: { marginLeft: 8 },
})(BotaoConsultar);
