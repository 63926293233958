import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";
import { createSnackbar, createDialogConfirm, AjaxBlackout } from "../../../../_common";

function ModalExcluirVarios({ ids, callback = () => null, callbackModalConfirmacao = () => null }) {
  const leads = ids.length;
  const excluirEmLote = leads >= 10;
  const intl = useIntl();
  const titulo = excluirEmLote
    ? intl.formatMessage(
        { defaultMessage: "Você excluirá {quantidade} leads, deseja continuar?" },
        { quantidade: leads }
      )
    : intl.formatMessage({ defaultMessage: `Excluir lead(s)` });

  function validarexclusao(value) {
    if (value !== leads.toString()) {
      return intl.formatMessage({ defaultMessage: "Informe o valor certo de leads a ser excluido" });
    }
    return "";
  }

  async function excluir() {
    AjaxBlackout.Show();
    try {
      let realizaExclusao = true;
      if (excluirEmLote) {
        const { data } = await axios.post(`/api/pipeline/lead/ValidaLeadsOutrosUsuarios`, ids);
        if (data) {
          realizaExclusao = false;
          callbackModalConfirmacao();
        }
      }

      if (realizaExclusao) {
        const { data } = await axios.post(`/api/pipeline/lead/excluir?confirmacaoQtdeLeads=${ids.length}`, ids);

        if (!data.success) throw Error("Ocorreu um erro");

        if (data.exibeMensagemProcessamento) {
          createDialogConfirm({
            title: intl.formatMessage({ defaultMessage: "Exclusão de leads" }),
            text: intl.formatMessage({
              defaultMessage:
                "Sua solicitação está em processamento e assim que concluída você será notificado por e-mail.",
            }),
            maxWidth: "sm",
            fullWidth: true,
            actions: unmount => (
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    unmount();
                    callback(true);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            ),
          });
        } else {
          callback(true);
        }
      }
    } catch (error) {
      createSnackbar(error.message);
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  return (
    <Formik initialValues={{ quantidadeLeads: "" }} onSubmit={excluir} onReset={() => callback(false)}>
      {bag => (
        <Form noValidate>
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            {excluirEmLote && (
              <>
                <DialogContentText>
                  {intl.formatMessage({
                    defaultMessage:
                      "Todos os dados e históricos vinculados aos leads serão excluídos definitivamente e não será possível desfazer a ação.",
                  })}
                </DialogContentText>
                <DialogContentText>
                  {intl.formatMessage({
                    defaultMessage: "Para continuar, digite o número de leads que serão excluídos:",
                  })}
                </DialogContentText>
              </>
            )}
            {!excluirEmLote && (
              <DialogContentText>
                {intl.formatMessage({
                  defaultMessage:
                    "Essa operação irá excluir este(s) lead(s) permanentemente. Deseja continuar com essa operação?",
                })}
              </DialogContentText>
            )}
            {excluirEmLote && (
              <Field
                component={TextField}
                label={intl.formatMessage({
                  defaultMessage: "Confirme a quantidade de leads a serem apagados",
                })}
                name="quantidadeLeads"
                required
                validate={validarexclusao}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={bag.handleReset}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button color="primary" type="submit">
              {excluirEmLote
                ? intl.formatMessage({ defaultMessage: "Confirmar exclusão de leads" })
                : intl.formatMessage({ defaultMessage: "Confirmar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

ModalExcluirVarios.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  callback: PropTypes.func,
  callbackModalConfirmacao: PropTypes.func,
};

export default ModalExcluirVarios;
