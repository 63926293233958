import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Dialog } from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";

import ModalExportar from "./ModalExportar";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import createSnackbar from "../../../../_common/utils/snackbar/createSnackbar";

function Exportar({ callback = x => x, children, leads = [], filtros, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);

  const close = response => {
    if (response)
      createSnackbar(
        response.status === 200
          ? intl.formatMessage({
              defaultMessage:
                "Exportação em processamento. Em breve lhe enviaremos um e-mail contendo os arquivos exportados.",
            })
          : response.statusText
      );
    setModalOpen(false);
    callback(response);
  };
  const intl = useIntl();
  return (
    <Fragment>
      <ActionButton
        type="icon"
        tooltip={intl.formatMessage({ defaultMessage: "Exportar lead" })}
        label={intl.formatMessage({ defaultMessage: "Exportar lead" })}
        onClick={() => setModalOpen(true)}
        icon={<GetApp />}
        claim={permissions.EXPORTAR}
        leads={leads}
        {...props}
      />
      <Dialog maxWidth="sm" fullWidth open={modalOpen} onClose={() => close(false)}>
        <ModalExportar ids={leads?.map(({ id }) => id)} {...props} callback={close} filtros={filtros} />
      </Dialog>
    </Fragment>
  );
}

Exportar.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  filtros: PropTypes.object,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      situacao: PropTypes.number,
      etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
    })
  ),
};

export default Exportar;
