import React from "react";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { maybeCallback } from "../../../../_common/utils/fp";

LeadEnderecoFormTextInput.propTypes = {
  onChangeCallback: PropTypes.func,
  field: PropTypes.object,
  form: PropTypes.object,
};

function LeadEnderecoFormTextInput({ onChangeCallback, ...props }) {
  props.field.onChange = e => {
    const previousValue = props.field.value;
    const nextValue = e.target.value;
    props.form.setFieldValue(e.target.name, nextValue);
    maybeCallback(onChangeCallback)(nextValue, previousValue);
  };

  return <TextField {...props} />;
}

export default LeadEnderecoFormTextInput;
