import permissions from "../../../../_common/permissions";
import { PERMISSAO_ETAPA, TIPO_GATE, SITUACAO_REUNIAO } from "../../../../_common/constantes";
import { useUsuario } from "../../../../hooks";
import usePermissaoEtapa from "../../../base-leads/pipeline/components/usePermissaoEtapa";

export function gerarLinkAgendamento(lead, isGerente, reagendar, permissao, redirecionarBaseLeads) {
  const situacao = lead.reuniao?.situacao || lead.agendamento?.situacao;
  const isAgendamento = lead.etapa?.tipoGate === TIPO_GATE.AGENDAMENTO;
  const id = lead.reuniao?.id || lead.agendamento?.id;

  const hasPermission = isGerente || reagendar || permissao === PERMISSAO_ETAPA.INTERAGIR;
  let shouldRender = hasPermission;

  /**
   * se lead tem data de descarte
   * se não tem permissão
   */
  if (!hasPermission) shouldRender = false;

  /**
   * se for reagendamento E não tem reunião
   * se for reagendamento E etapa atual NÃO é do tipo agendamento E a reunião foi conluída
   */
  if (reagendar) {
    if (isAgendamento && situacao === SITUACAO_REUNIAO.CONCLUIDO) shouldRender = false;
    if (!id || situacao === SITUACAO_REUNIAO.CONCLUIDO) shouldRender = false;
  }

  /**
   * se for agendamento E etapa atual NÃO é do tipo agendamento
   * se for agendamento E for a última etapa
   * se for agendamento E lead é descartado
   * se for agendamento E reunião foi cancelada
   * se for agendamento E reunião NÃO ocorreu
   */
  if (
    !reagendar &&
    (!isAgendamento ||
      !lead.etapa?.proximaEtapaId ||
      !lead.situacao ||
      situacao === SITUACAO_REUNIAO.CANCELADA ||
      situacao === SITUACAO_REUNIAO.NAO_OCORREU)
  )
    shouldRender = false;

  // return shouldRender;
  if (shouldRender)
    return `/spotter/reuniao?etapaId=${lead.etapa.id}&leadId=${lead.id}${reagendar ? "&reagendamento=true" : ""}${
      redirecionarBaseLeads ? "&redirecionarBaseLeads=true" : ""
    }`;
  return null;
}

export default function useAgendarReuniao(lead, reagendar, redirecionarBaseLeads = false) {
  const { isGerente } = useUsuario();
  const permissaoNaEtapa = usePermissaoEtapa(lead.etapa);
  const id = lead.reuniao?.id || lead.agendamento?.id;
  const defaultClaim = reagendar ? permissions.REAGENDAR : true;

  const link = gerarLinkAgendamento(lead, isGerente, reagendar, permissaoNaEtapa, redirecionarBaseLeads);

  return {
    defaultClaim,
    reuniaoId: id,
    hasPermission: !!link,
    redirecionar() {
      window.location.href = link;
    },
  };
}
