import React, { Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import useSWR from "swr";
import { useIntl, defineMessages } from "react-intl";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText } from "@material-ui/core";
import createSnackAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import { AjaxBlackout } from "../../../../_common";

const messages = defineMessages({
  desfazerVenda: {
    defaultMessage: "Desfazer Venda",
  },
  avisoDesfazer: {
    defaultMessage:
      "Ao desfazer a venda, este lead retornará para a etapa {etapa}. Tem certeza que deseja desfazer a venda?",
  },
  avisoReiniciar: {
    defaultMessage:
      "Ao desfazer a venda, o lead passará para o próximo ciclo e ele retornará para a etapa {etapa}. Tem certeza que deseja desfazer a venda?",
  },
  sim: { defaultMessage: "Sim" },
  nao: { defaultMessage: "Não" },
});

function ModalDesfazerVenda({ id, callback = () => null }) {
  const intl = useIntl();
  const { data: etapa } = useSWR(`/api/pipeline/venda/buscaretapa?leadId=${id}`);
  async function desfazerVenda() {
    AjaxBlackout.Show();
    try {
      const { data: success } = await axios.post(`/api/pipeline/venda/desfazer?leadId=${id}&etapaId=${etapa.id}`);
      callback(success, etapa.id);
    } catch (e) {
      createSnackAPIException(e);
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  if (!etapa) return null;

  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage(messages.desfazerVenda)}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>
          {intl.formatMessage(etapa.etapaOriginal ? messages.avisoDesfazer : messages.avisoReiniciar, {
            etapa: etapa.descricao,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="cancelar-desfazer-venda" onClick={() => callback(false)}>
          {intl.formatMessage(messages.nao)}
        </Button>
        <Button id="confirmar-desfazer-venda" onClick={desfazerVenda} color="primary">
          {intl.formatMessage(messages.sim)}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

ModalDesfazerVenda.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
};

export default ModalDesfazerVenda;
