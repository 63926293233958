import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { CircularProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "dot-prop-immutable";

import { CAMPOS_LEADS_SEMELHANTES } from "../../../_common/constantes";
import { useLeadsSemelhantes, useDebounce } from "../../../hooks";
import LeadsSemelhantes from "./LeadsSemelhantes";

function CampoSemelhantes({ name, campo, id, helperText = "", ...props }) {
  const { values, ...bag } = useFormikContext();
  const error = get(bag.errors, name);
  const touched = get(bag.touched, name);
  const hasError = !!error && touched;
  const value = get(values, name, "");
  const debounced = useDebounce(value, 2000);
  const [semelhantes, loading] = useLeadsSemelhantes(campo, debounced, id);

  return (
    <Field
      name={name}
      component={TextField}
      InputProps={{
        endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : undefined,
        error: !!semelhantes.quantidade || hasError,
      }}
      InputLabelProps={{ error: !!semelhantes.quantidade || hasError }}
      FormHelperTextProps={{ error: !!semelhantes.quantidade || hasError }}
      {...props}
      helperText={
        semelhantes.quantidade ? <LeadsSemelhantes {...semelhantes} /> : `${hasError ? bag.errors[name] : helperText}`
      }
    />
  );
}

CampoSemelhantes.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  campo: PropTypes.oneOf(Object.values(CAMPOS_LEADS_SEMELHANTES)).isRequired,
};

export default CampoSemelhantes;
