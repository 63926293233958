import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  VerAtividades,
  CancelarReuniao,
  ReenviarEmailFeedback,
  ReenviarDiagnostico,
  Vender,
  Duplicar,
  DesfazerVenda,
  Descartar,
  Recuperar,
  Excluir,
  AgendarReuniao,
  ConfirmarReuniao,
  Transferir,
  Ligar,
  RegistrarLigacao,
  IniciarFiltro,
  EnviarEmail,
  InserirCadencia,
  InserirWorkflow,
  ReiniciarCiclo,
  DesfazerPularEtapas,
  DesfazerTransferirDeFunil,
} from "../";

import VideoconferenciaURL from "../VideoconferenciaURL";
import PassarManualmente from "../PassarManualmente";

import { HorizMenu } from "../../../../components";
import { CriarAtividade } from "../../../atividade/components";
import WhatsAppHsm from "../WhatsAppHsm";
import SinalizarSearching from "../../../detalhes-lead/components/SinalizarSearching/SinalizarSearching";
import { useTipoPlanoSpotter, useUsuario, useWhatsApp } from "../../../../hooks";
import { eRequestStatus } from "../../../whatsapp/RegistrationRequest/constants";
import TransferirVendedor from "../TransferirVendedor";
import { SITUACAO_LEAD, TIPO_GATE } from "../../../../_common/constantes";
import AlterarTipoReuniao from "../AlterarTipoReuniao/AlterarTipoReuniao";
import LigacaoContatosLead from "../../../detalhes-lead/components/LigacaoContatosLead/LigacaoContatosLead";
import MudarFunil from "../MudarDeFunil";
import ResumoLeadIAModal from "../../../detalhes-lead/components/ResumoLeadIA/ResumoLeadIAModal";
import ResumoLeadIA from "../ResumoLeadIA";

FileiraAcoes.propTypes = {
  lead: PropTypes.object,
};

const reload = success => success && window.location.reload();

const reloadTimeline = success => {
  if (success) {
    document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
  }
};

const reloadCampanhas = success => {
  reloadTimeline(success);

  if (success) {
    document.dispatchEvent(new Event("detalhe-lead/campanhas/reload"));
  }
};

const reloadPainel = success => {
  reloadTimeline(success);
  if (success) document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
};

const goToBase = success => {
  if (success) window.location.href = "/spotter/base-leads/funil";
};

function FileiraAcoes({ lead }) {
  const { statusSolicitacao } = useWhatsApp();
  const { moverleadlivrementeentrefunis, featureToggles } = useUsuario();
  const [modalLigacaoIsOpen, setModalLigacaoIsOpen] = useState(false);
  const [resumoLeadIAIsOpen, setResumoLeadIAIsOpen] = useState(false);
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <>
      {modalLigacaoIsOpen && (
        <LigacaoContatosLead
          onClose={() => {
            setModalLigacaoIsOpen(false);
          }}
          leadId={lead.id.toString()}
        />
      )}

      {resumoLeadIAIsOpen && <ResumoLeadIAModal leadId={lead.id} setModalLigacaoIsOpen={setResumoLeadIAIsOpen} />}

      <EnviarEmail lead={lead} />

      <Ligar id={lead.id} leads={lead} setModalLigacaoIsOpen={setModalLigacaoIsOpen} />

      <RegistrarLigacao color="primary" type="text" id={lead.id} etapaId={lead.etapa?.id} callback={reloadTimeline} />

      {featureToggles.rolloutResumoLeadMentorIa && (
        <ResumoLeadIA id={lead.id} setResumoLeadIAIsOpen={setResumoLeadIAIsOpen} />
      )}

      <CriarAtividade lead={lead} callBack={reloadTimeline} />
      {statusSolicitacao !== eRequestStatus.cancelled && <WhatsAppHsm lead={lead} />}
      <PassarManualmente lead={lead} type="icon" onTransfer={reload} />
      <IniciarFiltro lead={lead} />
      <AgendarReuniao lead={lead} />
      {lead.situacao !== SITUACAO_LEAD.DESCARTADO && <AgendarReuniao lead={lead} reagendar />}
      <HorizMenu>
        {({ onClose }) => (
          <span>
            <VerAtividades lead={lead} nome={lead.nome} />
            <InserirWorkflow lead={lead} callback={reload} closeParentMenu={onClose} />
            {isSpotterFull && <InserirCadencia lead={lead} callback={reloadCampanhas} closeParentMenu={onClose} />}
            {!lead.dataDescarte && (
              <>
                <CancelarReuniao lead={lead} type="menu" callback={reloadPainel} />
                <ConfirmarReuniao lead={lead} type="menu" callback={reloadPainel} />
                <AlterarTipoReuniao lead={lead} type="menu" callback={reloadPainel} />
                <ReenviarEmailFeedback leads={[lead]} />
                <ReenviarDiagnostico leads={[lead]} />
              </>
            )}

            {lead.reenviarLinkVideoconferencia && (
              <>
                <VideoconferenciaURL lead={lead} />
                <VideoconferenciaURL vendedor lead={lead} />
              </>
            )}

            <Vender lead={lead} callback={reloadPainel} />
            {lead.etapa?.gateAnterior === TIPO_GATE.VENDA && <DesfazerVenda lead={lead} callback={reload} />}
            <DesfazerPularEtapas lead={lead} callback={reload} type="menu" />
            {lead.situacao !== SITUACAO_LEAD.DESCARTADO && <ReiniciarCiclo callback={reload} lead={lead} />}
            <Transferir leads={[lead]} callback={reload} />
            <Duplicar claim lead={lead} />
            <Descartar leads={[lead]} callback={reloadPainel} />
            <Recuperar leads={[lead]} type="menu" callback={reload} />
            {isSpotterFull && <TransferirVendedor leads={[lead]} type="menu" callback={reload} />}
            <DesfazerTransferirDeFunil lead={lead} type="menu" callback={reloadPainel} />
            {lead.situacao !== SITUACAO_LEAD.DESCARTADO &&
              (lead.etapa.ultimaEtapa || moverleadlivrementeentrefunis) &&
              isSpotterFull && (
                <MudarFunil
                  moverLivremente={moverleadlivrementeentrefunis}
                  lead={lead}
                  etapaLead={lead.etapa}
                  type="menu"
                  callback={reloadPainel}
                />
              )}
            {lead.searchingId && <SinalizarSearching lead={lead} type="menu" closeParentMenu={onClose} />}
            <Excluir key="button" leads={[lead]} callback={goToBase} />
          </span>
        )}
      </HorizMenu>
    </>
  );
}

export default FileiraAcoes;
