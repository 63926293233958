import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { CircularProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "dot-prop-immutable";
import { CAMPOS_LEADS_SEMELHANTES } from "../../../../_common/constantes";
import { useLeadsSemelhantes, useLeadsSemelhantesBloqueados } from "../../../../hooks";
import LeadsSemelhantes from "../LeadsSemelhantes";

function Email({
  name,
  index,
  lead,
  campoSemelhantes = CAMPOS_LEADS_SEMELHANTES.LEAD_EMAIL,
  campoSemelhantesBloqueados = CAMPOS_LEADS_SEMELHANTES.CONTATO_EMAIL,
}) {
  const { values, errors, validateField } = useFormikContext();
  const enderecoEmailPath = `${name}.${index}.endereco`;
  const value = get(values, enderecoEmailPath, "");
  const error = get(errors, enderecoEmailPath);
  const hasError = !!error;

  const [emailPesquisa, setEmailPesquisa] = useState(value);
  const [semelhantes, loadingSemelhantes] = useLeadsSemelhantes(campoSemelhantes, emailPesquisa, lead?.id);
  const { data: temSemelhanteBloqueado, isValidating: loadingBloqueados } = useLeadsSemelhantesBloqueados(
    campoSemelhantesBloqueados,
    emailPesquisa
  );
  const loading = loadingSemelhantes || loadingBloqueados;

  const helperText = () => {
    if (hasError) return get(errors, enderecoEmailPath);
    if (semelhantes.quantidade && emailPesquisa === value) return <LeadsSemelhantes {...semelhantes} />;
    return "";
  };

  // eslint-disable-next-line consistent-return
  const validateEmail = valor => {
    if (valor.trim().length > 0) {
      const pattern = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i);
      if (!pattern.test(valor)) return "Endereço de e-mail inválido";
      if (temSemelhanteBloqueado) return "Contato bloqueado";
    } else if (valor.length > 0 && valor.trim().length <= 0) {
      return "Endereço de e-mail inválido";
    }
  };

  React.useEffect(
    () => {
      if (!loadingBloqueados) {
        validateField(enderecoEmailPath);
      }
    },
    [emailPesquisa, loadingBloqueados, temSemelhanteBloqueado]
  );

  return (
    <Field
      name={enderecoEmailPath}
      component={TextField}
      style={{ flexGrow: 1 }}
      label={`E-mail ${index + 1}`}
      validate={validateEmail}
      InputProps={{
        endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : undefined,
        error: semelhantes.quantidade || hasError,
        onBlur: () => setEmailPesquisa(value),
      }}
      InputLabelProps={{ error: hasError || semelhantes.quantidade }}
      FormHelperTextProps={{ error: hasError || semelhantes.quantidade }}
      helperText={helperText()}
    />
  );
}

Email.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  lead: PropTypes.object,
  campoSemelhantes: PropTypes.oneOf(Object.keys(CAMPOS_LEADS_SEMELHANTES)),
  campoSemelhantesBloqueados: PropTypes.oneOf(Object.keys(CAMPOS_LEADS_SEMELHANTES)),
};

export default Email;
