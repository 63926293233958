import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import cn from "classnames";

import { useIntl } from "react-intl";
import { useEvent, useGet, useSelectPermission, useUsuario } from "../../../hooks";
import Outline from "./Outline";
import { ActionButton } from "../../../components";
import { createSnackbar, Tipografia, Flex, Loading, AjaxBlackout } from "../../../_common";
import permissions from "../../../_common/permissions";
import LeadInserirCadencia from "./InserirCadencia";
import { ACCESS_LEVEL } from "../../../_common/constantes";

function Campanhas({ id, lead, classes }) {
  const urlCampanha = "/spotter/cadencia-emails/campanha";
  const [campanhasList, loading, refetchCampanhas] = useGet(`/api/pipeline/lead/campanhas?id=${id}`);
  const intl = useIntl();
  const cadenciaPermission = useSelectPermission(permissions.CADENCIA_EMAILS);
  const { id: idUsuarioLogin } = useUsuario();

  useEvent("detalhe-lead/campanhas/reload", refetchCampanhas);

  const reload = success => {
    if (success) {
      refetchCampanhas();
      document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
    }
  };

  const onRemoveCampanha = async (leadId, campanhaId) => {
    try {
      AjaxBlackout.Show();
      await axios.post(`/api/pipeline/Historico/RemoverLeadDaCampanha?leadId=${leadId}&campanhaId=${campanhaId}`);

      createSnackbar(intl.formatMessage({ defaultMessage: "Operação realizada com sucesso!" }));
      document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
      refetchCampanhas();
    } catch (err) {
      createSnackbar(intl.formatMessage({ defaultMessage: "Erro ao remover Lead da Campanha." }));
    } finally {
      AjaxBlackout.Hide();
    }
  };

  const exibirLinkCampanha = usuarioId => {
    if (!cadenciaPermission) return false;

    if (cadenciaPermission.nivelAcesso === ACCESS_LEVEL.COMPLETE) return true;

    if (cadenciaPermission.nivelAcesso === ACCESS_LEVEL.INDIVIDUAL && usuarioId === idUsuarioLogin) return true;

    return false;
  };

  const temCampanhas = campanhasList.length > 0;

  return (
    <Outline
      card
      title={intl.formatMessage({
        defaultMessage: "Cadência de e-mails",
      })}
      id="card-Campanhas"
      action={
        temCampanhas ? (
          <LeadInserirCadencia
            lead={lead}
            callback={reload}
            ActionButtonProps={{
              id: "btn-cadencia-painel-shortcut",
              type: "icon",
              title: intl.formatMessage({ defaultMessage: "Inserir na Cadência de E-mails" }),
            }}
          />
        ) : null
      }
    >
      <Loading isLoading={loading}>
        <>
          {temCampanhas ? (
            <>
              {campanhasList.map(campanha => (
                <Flex
                  key={campanha.campanhaId}
                  className={
                    campanha.removidoCampanha ? cn(classes.linhaDesabilitada, classes.container) : classes.container
                  }
                  justifyContent="space-between"
                >
                  <Tipografia style={{ width: "40%" }}>
                    {exibirLinkCampanha(campanha.usuarioId) ? (
                      <Link to={`${urlCampanha}/${campanha.campanhaId}`}>{campanha.nomeCampanha}</Link>
                    ) : (
                      <Tipografia>{campanha.nomeCampanha}</Tipografia>
                    )}
                    <Tipografia italico>
                      {campanha.removidoCampanha ? ` (${intl.formatMessage({ defaultMessage: "Removido" })})` : ""}
                    </Tipografia>
                  </Tipografia>
                  <Tipografia style={{ width: "30%" }}>{campanha.statusCampanha}</Tipografia>
                  {campanha.podeRemoverCampanha && (
                    <Flex className={classes.botoes} justifyContent="flex-end">
                      <Tipografia cor="darkSecondaryText">
                        <Tooltip
                          title={intl.formatMessage({ defaultMessage: "Remover Lead da Campanha" })}
                          position="bottom"
                        >
                          <ActionButton
                            id="btn-remover-cadencia"
                            label={intl.formatMessage({ defaultMessage: "Remover Lead da Campanha" })}
                            icon={<DeleteIcon />}
                            onClick={() => onRemoveCampanha(id, campanha.campanhaId)}
                            claim={permissions.INSERIR_CADENCIA_MANUALMENTE}
                          />
                        </Tooltip>
                      </Tipografia>
                    </Flex>
                  )}
                </Flex>
              ))}
            </>
          ) : (
            <div>
              <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
                {intl.formatMessage({ defaultMessage: "Nenhuma campanha vinculada ao Lead" })}
              </Tipografia>
              <div className={classes.sendButton}>
                <LeadInserirCadencia
                  lead={lead}
                  callback={reload}
                  ActionButtonProps={{
                    icon: <SendIcon className={classes.sendButtonIcon} />,
                    id: "btn-cadencia-painel-empty",
                    color: "primary",
                    type: "button",
                  }}
                />
              </div>
            </div>
          )}
        </>
      </Loading>
    </Outline>
  );
}

Campanhas.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lead: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles({
  container: {
    width: "100%",
    height: 60,
    position: "relative",
    "&:hover $botoes": {
      visibility: "unset",
      display: "flex",
      position: "absolute",
    },
    alignItems: "center",
    "&:hover": { backgroundColor: "rgb(247,247,247)" },
  },
  sendButton: {
    marginTop: 12,
  },
  sendButtonIcon: {
    marginRight: 6,
  },
  botoes: {
    visibility: "hidden",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "absolute",
    marginRight: 24,
    right: 0,
    width: "10%",
    alignItems: "center",
  },
  linhaDesabilitada: {
    opacity: 0.5,
  },
  link: {
    paddingTop: "4px",
    textDecoration: "underline",
    fontStyle: "italic",
    fontSize: "11px",
  },
})(Campanhas);
