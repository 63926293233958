import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import SelectAutoComplete from "../../../../components/SelectAutoComplete";
import { maybeCallback } from "../../../../_common/utils/fp";

function LeadEnderecoFormSelect({
  dependentValue = "",
  data,
  label,
  isLoading,
  form,
  disabled,
  field,
  error,
  onChangeCallback,
  ...props
}) {
  const change = value => {
    const previousValue = field.value;
    form.setFieldValue(field.name, value, true);
    setTimeout(() => {
      form.validateForm();
      maybeCallback(onChangeCallback)(value, previousValue);
    });
  };

  const clearValue = () => change({ id: null, descricao: "", reseted: "LeadEnderecoFormSelect" });
  const intl = useIntl();

  React.useEffect(
    () => {
      if (!field.value || !field?.value.id) {
        clearValue();
        return;
      }

      if (Array.isArray(data)) {
        const target = data.find(({ descricao }) => descricao === field.value?.descricao);

        if (!target) {
          clearValue();
        }
      }
    },
    [dependentValue, field?.value?.id, data]
  );

  return (
    <SelectAutoComplete
      label={label}
      placeholder={
        isLoading
          ? intl.formatMessage({ defaultMessage: "Buscando..." })
          : intl.formatMessage({ defaultMessage: "Digite para filtrar" })
      }
      options={data}
      value={field.value}
      onChange={nome => {
        form.setFieldTouched(field.name, true);
        if (!nome) {
          clearValue();
          return;
        }
        const target = data.find(({ descricao }) => descricao === nome) || {};
        change({ id: target?.id, descricao: nome });
      }}
      disabled={!data?.length || disabled}
      isLoading={isLoading}
      error={error}
      dataScrollAnchor={field?.name}
      {...props}
    />
  );
}

LeadEnderecoFormSelect.propTypes = {
  dependentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeCallback: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  pais: PropTypes.number,
  field: PropTypes.object,
  form: PropTypes.object,
  error: PropTypes.string,
};

export default LeadEnderecoFormSelect;
