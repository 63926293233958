import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Divider, Dialog } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { useIntl } from "react-intl";
import ModalExcluir from "./ModalExcluir";
import ModalExcluirConfirmacao from "./ModalExcluirConfirmacao";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { usePermission } from "../../../../hooks";

Excluir.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  filtros: PropTypes.object,
  type: PropTypes.oneOf(["icon", "menu", "text"]),
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      situacao: PropTypes.number,
      etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
    })
  ),
};

function Excluir({ callback = x => x, children, leads = [], filtros, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmacaoOpen, setModalConfirmacaoOpen] = useState(false);

  const hasPermission = usePermission(permissions.APAGAR_LEAD, leads);

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  const closeModalConfirmacao = response => {
    setModalConfirmacaoOpen(false);
    callback(response);
  };

  const modalConfirmacao = () => {
    setModalOpen(false);
    setModalConfirmacaoOpen(true);
  };

  const intl = useIntl();
  return (
    <Fragment>
      {hasPermission && (!props.type || props.type === "menu") && <Divider />}
      <ActionButton
        id="btn-excluir"
        type="menu"
        label={intl.formatMessage({ defaultMessage: "Excluir" })}
        tooltip={intl.formatMessage({ defaultMessage: "Excluir Lead" })}
        icon={<Delete />}
        onClick={() => setModalOpen(true)}
        claim={permissions.APAGAR_LEAD}
        leads={leads}
        {...props}
      />
      <Dialog disableBackdropClick maxWidth="sm" fullWidth open={modalOpen} onClose={() => close(false)}>
        <ModalExcluir
          ids={leads.map(lead => lead.id)}
          {...props}
          callback={close}
          filtros={filtros}
          callbackModalConfirmacao={modalConfirmacao}
        />
      </Dialog>
      <Dialog
        disableBackdropClick
        maxWidth="sm"
        fullWidth
        open={modalConfirmacaoOpen}
        onClose={() => closeModalConfirmacao(false)}
      >
        <ModalExcluirConfirmacao ids={leads.map(lead => lead.id)} {...props} callback={closeModalConfirmacao} />
      </Dialog>
    </Fragment>
  );
}

export default Excluir;
