import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

import permissions from "../../../../_common/permissions";
import { ActionButton } from "../../../../components";
import { usePermission } from "../../../../hooks";

export default function Editar({ lead, ...props }) {
  const hasPermission = usePermission(permissions.VISUALIZAR_DETALHE, lead);
  if (!hasPermission) return null;
  const intl = useIntl();
  return (
    <Link to={`/spotter/detalhes-lead/${lead.id}`}>
      <ActionButton
        type="icon"
        icon={<Edit />}
        tooltip={intl.formatMessage({ defaultMessage: "Editar lead" })}
        label={intl.formatMessage({ defaultMessage: "Editar lead" })}
        style={{ height: "100%" }}
        claim={permissions.EDITAR}
        leads={lead}
        {...props}
      />
    </Link>
  );
}

Editar.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
  }),
};
