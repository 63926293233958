import React from "react";
import { Grid } from "@material-ui/core";
import { useFormikContext, Field } from "formik";
import { TextField } from "formik-material-ui";
import useSWR from "swr";

import { useIntl } from "react-intl";
import InputCep from "../../../../components/InputCep";
import { INPUT_MAX_LENGTH, PAIS_BRASIL } from "../../../../_common/constantes";
import useCepService from "../../../../hooks/useCepService";
import LeadEnderecoFormSelect from "./Select";
import usetGetCached from "../../../../hooks/useGetCached";
import LeadEnderecoFormTextInput from "./TextInput";

const DEFAULT_ENDERECO = {
  pais: { id: null, descricao: "" },
  estado: { id: null, descricao: "" },
  cidade: { id: null, descricao: "" },
  logradouro: "",
  bairro: "",
  complemento: "",
  numero: "",
  cep: "",
};

const shouldAutoComplete = paisId => !paisId || +paisId === +PAIS_BRASIL.id;

function LeadEnderecoForm() {
  const {
    isSubmitting,
    values,
    errors,
    setValues,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    touched,
    initialValues,
    handleBlur,
    validateForm,
  } = useFormikContext();

  const [getCep, isValidatingCep, cepServiceMetaData] = useCepService();
  const [cepAutocomplete, setCepAutocomplete] = React.useState(null);

  const temCidade = !!values.endereco?.cidade?.id || !!values.endereco?.cidade?.descricao;
  const paisId = values.endereco?.pais?.id;
  const estadoId = values.endereco?.estado?.id;
  const isBrasil = paisId === PAIS_BRASIL.id;
  const intl = useIntl();

  const { response: paises, isLoading: isLoadingPaises } = usetGetCached(`/Api/Pipeline/Pais/Listar`);
  const { response: estados, isLoading: isLoadingEstados } = usetGetCached(
    `/api/pipeline/estado/listar?paisid=${PAIS_BRASIL.id}`
  );

  const { data: cidades, isValidating: isLoadingCidades, mutate: mutateCidades } = useSWR(
    !!estadoId && isBrasil ? `/api/pipeline/cidade/listar?estadoid=${estadoId}` : null
  );

  React.useEffect(
    () => {
      if (isBrasil) {
        setFieldValue("endereco.estado", getEstado());
        setFieldValue("endereco.cidade", getCidade());

        if (touched?.endereco?.cidade) {
          setFieldTouched("endereco.cidade.descricao", false);
        }
        if (touched?.endereco?.estado) {
          setFieldTouched("endereco.estado.descricao", false);
        }
      }

      if (isBrasil && cepAutocomplete) {
        setFieldValue("endereco.estado", getEstado(cepAutocomplete, estados));
      }

      if (!isBrasil) {
        setFieldTouched("endereco.cidade", false);
        setFieldTouched("endereco.estado", false);
        setFieldError("endereco.cidade", null);
        setFieldError("endereco.estado", null);
        setCepAutocomplete(null);
      }

      if (!paisId) {
        clearAddress();
      }
    },
    [paisId, cepAutocomplete]
  );

  React.useEffect(
    () => {
      if (!estadoId) {
        setFieldValue("endereco.cidade", getCidade());
        mutateCidades(null, false);
        return;
      }

      mutateCidades(null);
    },
    [estadoId, cepAutocomplete]
  );

  React.useEffect(
    () => {
      if (isBrasil && cepAutocomplete) {
        setFieldValue("endereco.cidade", getCidade(cepAutocomplete, cidades));
        setTimeout(() => {
          validateForm();
        });
      }
    },
    [cidades, cepAutocomplete]
  );

  /**
   * Atualiza o touched previamente.
   * Normalmente seria atualizado de forma assincrona no evento onBlur.
   * É necessário para controlar o reset do Estado e Cidade
   * corretamente quando for edição de endereço.
   */
  React.useEffect(
    () => {
      if (!touched?.endereco?.cep) {
        setFieldTouched("endereco.cep", true);
      }
    },
    [values?.endereco?.cep]
  );

  const onBlurAutocompleteCep = async e => {
    handleBlur(e);

    if (!touched?.endereco?.cep) {
      return;
    }

    const { cep } = values.endereco;

    if (!cep || !!errors.endereco?.cep) {
      setCepAutocomplete(null);
      return;
    }

    if (!shouldAutoComplete(paisId)) {
      return;
    }

    try {
      const cepInfo = await getCep(cep);

      if (!cepInfo) throw Error();

      setCepAutocomplete(cepInfo);
      autoCompleteAddress(cepInfo);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      setCepAutocomplete(null);
      resetAutoCompleteAddress();
    }
  };

  const autoCompleteAddress = (cepInfo = {}) => {
    setValues({
      ...values,
      endereco: {
        ...values.endereco,
        estado: getEstado(cepInfo),
        pais: PAIS_BRASIL,
        logradouro: cepInfo.logradouro,
        bairro: cepInfo.bairro,
      },
    });
  };

  const resetAutoCompleteAddress = () => {
    setValues({
      ...values,
      endereco: {
        ...DEFAULT_ENDERECO,
        ...values.endereco,
        estado: getEstado(),
        cidade: getCidade(),
      },
    });
  };

  const clearAddress = () => {
    setValues({
      ...values,
      endereco: {
        ...DEFAULT_ENDERECO,
        pais: values?.endereco?.pais || DEFAULT_ENDERECO.pais,
      },
    });
  };

  const getEstado = (cepInfo, estadosList = estados) => {
    if (!cepInfo) {
      return defaultEstado();
    }

    if (!estadosList) {
      return DEFAULT_ENDERECO.cidade;
    }

    return estadosList.find(({ uf }) => uf === cepInfo.uf) || DEFAULT_ENDERECO.estado;
  };

  const getCidade = (cepInfo, cidadesList = cidades) => {
    if (!cepInfo) {
      return defaultCidade();
    }

    if (!cidadesList) {
      return DEFAULT_ENDERECO.cidade;
    }

    return cidadesList.find(({ descricao }) => descricao === cepInfo.localidade) || DEFAULT_ENDERECO.cidade;
  };

  const defaultEstado = () => {
    if (touched?.endereco?.cep || touched?.endereco?.pais || touched?.endereco?.estado) {
      return DEFAULT_ENDERECO.estado;
    }

    return initialValues.endereco.estado;
  };

  const defaultCidade = () => {
    if (touched?.endereco?.cep || touched?.endereco?.pais || touched?.endereco?.estado || touched?.endereco?.cidade) {
      return DEFAULT_ENDERECO.cidade;
    }

    return initialValues.endereco.cidade;
  };

  return (
    <Grid container spacing={16}>
      <Grid item xs={6}>
        {!isLoadingPaises && (
          <Field
            label={intl.formatMessage({
              defaultMessage: "País",
            })}
            data={paises}
            component={LeadEnderecoFormSelect}
            name="endereco.pais"
            disabled={isSubmitting || isValidatingCep || isLoadingPaises}
            onChangeCallback={(val, previousVal) => {
              // Caso tenha editado para um país diferente do inicial
              setFieldTouched("endereco.pais", true);
              if (val?.id !== previousVal?.id && previousVal?.id === PAIS_BRASIL.id) {
                setFieldValue("endereco.cep", "");
                setFieldError("endereco.estado", null);
                setFieldError("endereco.cidade", null);
                setFieldValue("endereco.estado", DEFAULT_ENDERECO.estado);
                setFieldValue("endereco.cidade", DEFAULT_ENDERECO.cidade);
              }
            }}
          />
        )}
      </Grid>

      {isBrasil && (
        <>
          <Grid item xs={6}>
            <InputCep
              name="endereco.cep"
              disabled={isSubmitting || isValidatingCep}
              isLoading={isValidatingCep || cepServiceMetaData?.isLoading}
              onBlur={onBlurAutocompleteCep}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              dependentValue={paisId}
              label={intl.formatMessage({
                defaultMessage: "Estado",
              })}
              name="endereco.estado"
              component={LeadEnderecoFormSelect}
              data={estados}
              isLoading={isLoadingEstados || isValidatingCep}
              disabled={isLoadingEstados || isSubmitting || isValidatingCep}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              dependentValue={estadoId}
              label={intl.formatMessage({
                defaultMessage: "Cidade",
              })}
              name="endereco.cidade"
              component={LeadEnderecoFormSelect}
              data={cidades}
              isLoading={isLoadingCidades || isLoadingEstados || isValidatingCep}
              disabled={!estadoId || isSubmitting || isValidatingCep}
              required={!!estadoId}
              validate={val =>
                estadoId && !val?.id ? intl.formatMessage({ defaultMessage: "Campo obrigatório*" }) : ""
              }
              error={
                touched.endereco?.cidade && typeof errors?.endereco?.cidade === "string" ? errors?.endereco?.cidade : ""
              }
              size={10}
            />
          </Grid>
        </>
      )}

      {!isBrasil && (
        <>
          <Grid item xs={6}>
            <Field
              fullWidth
              component={TextField}
              label={intl.formatMessage({
                defaultMessage: "Código Postal",
              })}
              name="endereco.cep"
              disabled={!paisId || isSubmitting}
              inputProps={{ maxLength: INPUT_MAX_LENGTH.CODIGO_POSTAL }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              label={intl.formatMessage({
                defaultMessage: "Estado",
              })}
              name="endereco.estado.descricao"
              component={LeadEnderecoFormTextInput}
              disabled={!paisId || isSubmitting}
              inputProps={{ maxLength: INPUT_MAX_LENGTH.ESTADO }}
              required={!!paisId}
              validate={val => (paisId && !val ? intl.formatMessage({ defaultMessage: "Campo obrigatório*" }) : "")}
              error={touched.endereco?.estado?.descricao && errors?.endereco?.estado?.descricao}
              onChangeCallback={val => {
                if (values.endereco.estado?.id) {
                  setFieldValue("endereco.estado", { descricao: val, id: null }, true);
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              fullWidth
              label={intl.formatMessage({
                defaultMessage: "Cidade",
              })}
              name="endereco.cidade.descricao"
              component={LeadEnderecoFormTextInput}
              disabled={!paisId || isSubmitting}
              inputProps={{ maxLength: INPUT_MAX_LENGTH.CIDADE }}
              required={!!paisId}
              validate={val => (paisId && !val ? intl.formatMessage({ defaultMessage: "Campo obrigatório*" }) : "")}
              error={touched.endereco?.cidade?.descricao && errors?.endereco?.cidade?.descricao}
              onChangeCallback={val => {
                if (values.endereco.cidade?.id) {
                  setFieldValue("endereco.cidade", { descricao: val, id: null });
                }
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={6}>
        <Field
          component={TextField}
          fullWidth
          name="endereco.logradouro"
          label={intl.formatMessage({
            defaultMessage: "Rua",
          })}
          inputProps={{ maxLength: INPUT_MAX_LENGTH.LOGRADOURO }}
          disabled={isSubmitting || isValidatingCep}
          validate={val =>
            val && !temCidade ? intl.formatMessage({ defaultMessage: "Campos Estado e Cidade são obrigatórios" }) : ""
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          fullWidth
          name="endereco.numero"
          label={intl.formatMessage({
            defaultMessage: "Número",
          })}
          inputProps={{ maxLength: INPUT_MAX_LENGTH.NUMERO }}
          disabled={isSubmitting || isValidatingCep}
          validate={val =>
            val && !temCidade ? intl.formatMessage({ defaultMessage: "Campos Estado e Cidade são obrigatórios" }) : ""
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          fullWidth
          name="endereco.complemento"
          label={intl.formatMessage({
            defaultMessage: "Complemento",
          })}
          inputProps={{ maxLength: INPUT_MAX_LENGTH.COMPLEMENTO }}
          disabled={isSubmitting || isValidatingCep}
          validate={val =>
            val && !temCidade ? intl.formatMessage({ defaultMessage: "Campos Estado e Cidade são obrigatórios" }) : ""
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          fullWidth
          name="endereco.bairro"
          label={intl.formatMessage({
            defaultMessage: "Bairro",
          })}
          inputProps={{ maxLength: INPUT_MAX_LENGTH.BAIRRO }}
          disabled={isSubmitting || isValidatingCep}
          validate={val =>
            val && !temCidade ? intl.formatMessage({ defaultMessage: "Campos Estado e Cidade são obrigatórios" }) : ""
          }
        />
      </Grid>
    </Grid>
  );
}

export default LeadEnderecoForm;
