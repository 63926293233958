import React, { Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useIntl } from "react-intl";
import { DialogContent, DialogActions, Button, DialogContentText } from "@material-ui/core";
import DialogTitleClose from "../../../../_common/components/DialogTitleClose/DialogTitleClose";
import { AjaxBlackout } from "../../../../_common";

function ModalDuplicarVarios({ funilId, etapaId, id, callback = () => null, close }) {
  const intl = useIntl();

  function duplicar(dividirLeads) {
    AjaxBlackout.Show();
    axios
      .post(`/api/pipeline/lead/duplicar?id=${id}&dividirLeads=${dividirLeads}&funilId=${funilId}&etapaId=${etapaId}`)
      .then(({ data: leadsIds }) => {
        callback(leadsIds);
      })
      .catch(close)
      .finally(() => AjaxBlackout.Hide());
  }

  return (
    <Fragment style={{ width: "100px" }}>
      <DialogTitleClose onClose={close}>
        {intl.formatMessage({
          defaultMessage: "Duplicar lead por produto",
        })}
      </DialogTitleClose>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage:
              "Este lead possui mais de um produto cadastrado/detectado. Você deseja criar um novo lead (cópia) para cada produto? Todos os leads serão criados na etapa selecionada.",
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => duplicar(false)}>{intl.formatMessage({ defaultMessage: "ÚNICO LEAD" })}</Button>
        <Button onClick={() => duplicar(true)} color="primary">
          {intl.formatMessage({ defaultMessage: "UM LEAD POR PRODUTO" })}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

ModalDuplicarVarios.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  callback: PropTypes.func,
  close: PropTypes.func,
  funilId: PropTypes.number,
  etapaId: PropTypes.number,
};

export default ModalDuplicarVarios;
