import React, { Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import useSWR from "swr";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useGet } from "../../../../hooks";
import { AjaxBlackout, Loading } from "../../../../_common";
import AsyncSelect from "../../../../components/AsyncSelect";

function ModalDuplicar(
  { setFunilId, setEtapaId, id, classes, callback = () => null, callbackDuplicarVarios = () => null },
  props
) {
  const [funil, setFunil] = React.useState();
  const [etapa, setEtapa] = React.useState();
  const [totalprodutos, isLoading] = useGet(`/api/pipeline/lead/ValidarProdutosDuplicar?id=${id}`);
  const { data: funis, isValidating } = useSWR("/api/pipeline/funil/listarComEtapas");
  const intl = useIntl();
  const multiplosProdutos = totalprodutos > 1;

  const etapas = [];
  if (!isValidating)
    funis.forEach(f => {
      f.etapas
        .filter(e => !e.posVenda)
        .map(e => etapas.push({ id: e.id, nome: `${e.nome} - ${f.nome}`, ativo: f.posVenda, funilId: f.id }));
    });

  const alterarEtapa = e => {
    setEtapa(e);
    setEtapaId(e);
    setFunil(etapas.find(x => x.id === e)?.funilId);
    setFunilId(etapas.find(x => x.id === e)?.funilId);
  };

  const label = !multiplosProdutos
    ? intl.formatMessage({ defaultMessage: "Duplicar" })
    : intl.formatMessage({ defaultMessage: "Avançar" });

  function duplicar() {
    AjaxBlackout.Show();
    axios
      .post(`/api/pipeline/lead/duplicar?id=${id}&funilid=${funil}&etapaId=${etapa}`)
      .then(({ data: leadId }) => callback(leadId))
      .catch(callback)
      .finally(() => AjaxBlackout.Hide());
  }

  return (
    <Fragment>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Duplicar lead" })}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage: "Deseja duplicar o lead? O novo lead será inserido na etapa selecionada.",
          })}
        </DialogContentText>

        <Loading isLoading={isValidating} className={classes.loading}>
          {!isValidating && (
            <div className={classes.container}>
              {
                <AsyncSelect
                  id="select-etapa"
                  getLabel={item => item.nome}
                  label={intl.formatMessage({ defaultMessage: "Etapa" })}
                  enableSearch
                  fullWidth
                  onChange={event => alterarEtapa(event.target.value)}
                  options={etapas}
                  {...props}
                  value={etapa}
                />
              }
            </div>
          )}
        </Loading>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(false)}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        {isLoading ? (
          <Button />
        ) : (
          <Button onClick={multiplosProdutos ? callbackDuplicarVarios : duplicar} color="primary">
            {label}
          </Button>
        )}
      </DialogActions>
    </Fragment>
  );
}

ModalDuplicar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  callback: PropTypes.func,
  callbackDuplicarVarios: PropTypes.func,
  setFunilId: PropTypes.func,
  setEtapaId: PropTypes.func,
};

export default withStyles(() => ({
  container: {
    paddingLeft: 14,
  },
  loading: {
    maxHeight: "100px",
  },
}))(ModalDuplicar);
