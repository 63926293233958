import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Dialog } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import ModalDuplicar from "./ModalDuplicar";
import ModalDuplicarVarios from "./ModalDuplicarVarios";
import { ActionButton } from "../../../../components";

Duplicar.propTypes = {
  callback: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
  }),
};

const abrirLinks = leadsIds => {
  if (!leadsIds?.length) return;

  if (leadsIds.length > 1) {
    window.location.href = `/spotter/leads`;
  } else {
    window.location.href = `/spotter/detalhes-lead/${leadsIds[0]}`;
  }
};

function Duplicar({ callback = abrirLinks, lead = {}, ...props }) {
  const [modalDuplicarOpen, setModalDuplicarOpen] = useState(false);
  const [modalDuplicarVariosOpen, setModalDuplicarVariosOpen] = useState(false);

  const close = response => {
    setModalDuplicarOpen(false);
    callback(response);
  };

  const closeDuplicarVarios = () => {
    setModalDuplicarVariosOpen(false);
  };

  const duplicarVarios = () => {
    setModalDuplicarOpen(false);
    setModalDuplicarVariosOpen(true);
  };

  const atualizarCallback = response => {
    closeDuplicarVarios();
    callback(response);
  };
  const intl = useIntl();

  const [funilId, setFunilId] = useState();
  const [etapaId, setEtapaId] = useState();
  return (
    <>
      <Fragment>
        <ActionButton
          id="btn-duplicar"
          type="menu"
          onClick={() => setModalDuplicarOpen(true)}
          icon={<FileCopy />}
          label={intl.formatMessage({ defaultMessage: "Duplicar lead" })}
          {...props}
        />
        <Dialog disableBackdropClick open={modalDuplicarOpen} onClose={() => close(false)}>
          <ModalDuplicar
            setFunilId={setFunilId}
            setEtapaId={setEtapaId}
            id={lead.id}
            {...props}
            callback={close}
            callbackDuplicarVarios={duplicarVarios}
          />
        </Dialog>
        <Dialog
          disableBackdropClick
          open={modalDuplicarVariosOpen}
          onClose={() => closeDuplicarVarios(false)}
          maxWidth="sm"
          fullWidth
        >
          <ModalDuplicarVarios
            funilId={funilId}
            etapaId={etapaId}
            id={lead.id}
            {...props}
            callback={atualizarCallback}
            close={closeDuplicarVarios}
          />
        </Dialog>
      </Fragment>
    </>
  );
}
export default Duplicar;
