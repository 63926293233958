import React, { useState } from "react";
import PropTypes from "prop-types";
import EventBusy from "@material-ui/icons/EventBusy";
import { Dialog } from "@material-ui/core";
import { useIntl } from "react-intl";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { PERMISSAO_ETAPA, SITUACAO_LEAD, SITUACAO_REUNIAO } from "../../../../_common/constantes";
import { useUsuario } from "../../../../hooks";
import ModalCancelarReuniao from "./ModalCancelarReuniao";
import usePermissaoEtapa from "../../../base-leads/pipeline/components/usePermissaoEtapa";

CancelarReuniao.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    etapa: PropTypes.shape({
      id: PropTypes.number.isRequired,
      gateAnterior: PropTypes.number,
      funilId: PropTypes.number,
    }).isRequired,
    reuniao: PropTypes.object,
    dataDescarte: PropTypes.string,
    situacao: PropTypes.number,
  }).isRequired,
};

function CancelarReuniao({ id, callback = () => null, lead = {}, ...props }) {
  if (
    lead.situacao !== SITUACAO_LEAD.EM_OPERACAO ||
    (lead.reuniao &&
      (lead.reuniao.situacao !== SITUACAO_REUNIAO.VIGENTE && lead.reuniao.situacao !== SITUACAO_REUNIAO.REAGENDADA))
  )
    return null;

  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(o => !o);
  const permissaoNaEtapa = usePermissaoEtapa(lead.etapa);

  const { isGerente } = useUsuario();
  const hasPermission = lead.etapa && (isGerente || permissaoNaEtapa === PERMISSAO_ETAPA.INTERAGIR);

  if (!hasPermission || !lead.reuniao?.id) return null;

  return (
    <>
      <ActionButton
        id="btnCancelarReuniao"
        type="icon"
        icon={<EventBusy />}
        label={intl.formatMessage({ defaultMessage: "Cancelar Reunião" })}
        onClick={toggle}
        claim={permissions.CANCELAR_REUNIAO}
        leads={lead}
        {...props}
      />
      <Dialog maxWidth="sm" fullWidth open={open} onClose={toggle}>
        <ModalCancelarReuniao leadId={id ?? lead.id} callback={callback} onClose={toggle} />
      </Dialog>
    </>
  );
}

export default CancelarReuniao;
