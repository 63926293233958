import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { IconButton, Radio, withStyles } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import AddCircle from "@material-ui/icons/AddCircle";
import { get, set } from "dot-prop-immutable";
import { v4 } from "uuid";

import { Flex } from "../../../../_common";
import Email from "./Email";

export const GET_DEFAULT_EMAIL = () => ({ uid: v4(), endereco: "", principal: true });

function Emails({ name, lead, classes }) {
  const { values, setFieldValue } = useFormikContext();
  const emails = get(values, name);

  React.useEffect(() => {
    if (!emails || !emails.length) setFieldValue(name, [GET_DEFAULT_EMAIL()]);
  }, []);

  React.useEffect(
    () => {
      const emailPrincipal = emails.find(({ principal }) => !!principal);
      if (!emailPrincipal && emails.length) {
        setFieldValue(`${name}[0]`, { ...emails[0], principal: true });
      }
    },
    [emails]
  );
  return (
    <FieldArray
      name={name}
      render={({ push, remove, form: { setValues, isSubmitting } }) => (
        <Fragment>
          {emails.map(({ id, uid, principal }, index) => (
            <Flex key={id || uid} className={classes.row} justifyContent="space-between">
              <Flex className={classes.container} justifyContent="space-between">
                <Email name={name} index={index} lead={lead} />
              </Flex>

              <Flex justifyContent="flex-end" className={classes.actions}>
                <IconButton
                  className={emails.length > 1 ? classes.showOnHover : classes.hidden}
                  onClick={() => remove(index)}
                  disabled={isSubmitting}
                >
                  <Clear />
                </IconButton>

                <IconButton
                  className={index + 1 < emails.length ? classes.hidden : ""}
                  onClick={() => push({ uid: v4(), endereco: "", principal: false })}
                  disabled={isSubmitting}
                >
                  <AddCircle />
                </IconButton>

                <Radio
                  checked={principal}
                  onChange={() =>
                    setValues(set(values, name, emails.map((email, ind) => ({ ...email, principal: index === ind }))))
                  }
                  disabled={isSubmitting}
                />
              </Flex>
            </Flex>
          ))}
        </Fragment>
      )}
    />
  );
}

Emails.propTypes = {
  name: PropTypes.string,
  lead: PropTypes.object,
};

export default withStyles({
  container: {
    flexGrow: 1,
  },
  row: { "&:hover $showOnHover": { visibility: "visible" }, margin: "16px 0" },
  actions: {
    minWidth: 144,
    width: 144,
    alignSelf: "flex-end",
  },
  showOnHover: { visibility: "hidden" },
  hidden: { visibility: "hidden" },
  input: {
    width: 144,
    "&:first-child": { marginRight: 24 },
  },
})(Emails);
