import { useMemo, useCallback } from "react";
import useSWR from "swr";
import axios from "axios";
import { appIntl } from "../../../../_common/containers/ConnectedIntlProvider";

import { AjaxBlackout, createAsyncDialog, createSnackbar, createSnackbarAPIException } from "../../../../_common";

export async function desfazerTransferirDeFunil(lead, callback) {
  try {
    const intl = appIntl();
    await createAsyncDialog({
      title: intl.formatMessage({ defaultMessage: "Desfazer transferência entre funis" }),
      text: intl.formatMessage(
        {
          defaultMessage: "Deseja desfazer a transferência entre funis e voltar para {funilOriginal}?",
        },
        {
          funilOriginal: lead?.conversao.funil,
        }
      ),
      tamanhoMaximo: "sm",
      acceptLabel: intl.formatMessage(
        { defaultMessage: "Voltar para {funilOriginal}" },
        { funilOriginal: lead?.conversao.funil }
      ),
    });
    AjaxBlackout.Show();
    const [etapaDesfazerPuloId, etapaAtualId] = [lead?.conversao?.etapaDesfazerPuloId, lead?.etapa.id];
    const { data: success } = await axios.post("/api/pipeline/lead/DesfazerTransferirDeFunil", {
      id: lead.id,
      etapaDesfazerPuloId,
      etapaAtualId,
    });
    createSnackbar(intl.formatMessage({ defaultMessage: "Lead retornado ao funil anterior com sucesso." }));
    callback(success, etapaDesfazerPuloId, etapaAtualId);
  } catch (err) {
    if (err) createSnackbarAPIException(err);
    // user declined, do nothing
  } finally {
    AjaxBlackout.Hide();
  }
}

export default function useDesfazerTransferirDeFunil({ lead, leadId = lead?.id, callback }) {
  const { data: painel, mutate } = useSWR(
    !lead || lead?.conversao?.etapaDesfazerPuloId ? `/api/pipeline/lead/painel?id=${leadId}` : null,
    { fallbackData: lead, revalidateOnMount: !lead }
  );

  const clickHandler = useCallback(
    async () => {
      await desfazerTransferirDeFunil(painel, callback);
      mutate();
    },
    [painel]
  );

  return useMemo(() => [painel, clickHandler], [painel, clickHandler]);
}
