import { useMemo, useCallback } from "react";
import useSWR from "swr";
import axios from "axios";
import { appIntl } from "../../../../_common/containers/ConnectedIntlProvider";

import { AjaxBlackout, createAsyncDialog, createSnackbarAPIException } from "../../../../_common";

export async function desfazerPularEtapas(etapaOriginal, etapaAtual, lead, callback) {
  try {
    const intl = appIntl();
    await createAsyncDialog({
      title: intl.formatMessage({ defaultMessage: "Desfazer pulo entre etapas" }),
      text: intl.formatMessage(
        {
          defaultMessage: "Deseja desfazer o pulo que o lead fez entre {etapaOriginal} para {etapaAtual}?",
        },
        {
          etapaOriginal: etapaOriginal?.nome,
          etapaAtual: etapaAtual.nome,
        }
      ),
      tamanhoMaximo: "sm",
      acceptLabel: intl.formatMessage({ defaultMessage: "Voltar para {etapa}" }, { etapa: etapaOriginal?.nome }),
    });
    AjaxBlackout.Show();
    const [etapaDesfazerPuloId, etapaAtualId] = [etapaOriginal.id, etapaAtual.id];
    const { data: success } = await axios.post("/api/pipeline/lead/desfazerPularEtapas", {
      id: lead.id,
      etapaDesfazerPuloId,
      etapaAtualId,
    });
    callback(success, etapaDesfazerPuloId, etapaAtualId);
  } catch (err) {
    if (err) createSnackbarAPIException(err);
    // user declined, do nothing
  } finally {
    AjaxBlackout.Hide();
  }
}

export default function useDesfazerPularEtapas({ lead, leadId = lead?.id, callback }) {
  const { data: painel, mutate } = useSWR(
    !lead || lead?.conversao?.etapaDesfazerPuloId ? `/api/pipeline/lead/painel?id=${leadId}` : null,
    { fallbackData: lead, revalidateOnMount: !lead }
  );

  const { data: etapas = [] } = useSWR(
    painel?.conversao?.etapaDesfazerPuloId ? `/api/pipeline/etapa/listar?todosFunis=true&funisAtivos=true` : null,
    {
      fallbackData: painel?.etapaOriginal ? [lead.etapaOriginal] : undefined,
      revalidateOnMount: !painel?.etapaOriginal,
    }
  );

  const etapaOriginal = useMemo(() => etapas?.find(etapa => etapa.id === painel?.conversao?.etapaDesfazerPuloId), [
    etapas,
    painel?.etapa?.etapaDesfazerPuloId,
  ]);

  const clickHandler = useCallback(
    async () => {
      await desfazerPularEtapas(etapaOriginal, lead.etapa, painel, callback);
      mutate();
    },
    [painel, callback, etapaOriginal]
  );

  return useMemo(() => [painel, clickHandler], [painel, clickHandler]);
}
