import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { DialogContent, DialogTitle, DialogActions, Button, withStyles } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl, defineMessages } from "react-intl";
import useSWR from "swr";
import Moment from "moment";
import { useHistory } from "react-router";
import cn from "classnames";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import MailIcon from "@material-ui/icons/Mail";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@material-ui/icons/Schedule";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { FormikAsyncSelect } from "../../../../components/AsyncSelect";
import { AjaxBlackout, createDialogConfirm, Flex, createSnackbar } from "../../../../_common";
import { NU_TIPO_ATIVIDADE_ID } from "../../../../_common/constantes";

const INITIAL_VALUES = {
  tipoAtividadeId: 0,
  templateEmailId: null,
  observacoes: "",
  dia: "",
  horas: "",
  notificarId: null,
};

export const TEMPO_NOTIFICACAO_LABELS = defineMessages({
  naoNotificar: { defaultMessage: "Não notificar" },
  naHoraAgendada: { defaultMessage: "Na hora agendada" },
  notificar5MinutosAntes: { defaultMessage: "5 minutos antes" },
  notificar15MinutosAntes: { defaultMessage: "15 minutos antes" },
  notificar30MinutosAntes: { defaultMessage: "30 minutos antes" },
  notificar1HoraAntes: { defaultMessage: "1 hora antes" },
});

const TEMPO_NOTIFICACAO = [
  { id: -1, descricao: TEMPO_NOTIFICACAO_LABELS.naoNotificar },
  { id: 0, descricao: TEMPO_NOTIFICACAO_LABELS.naHoraAgendada },
  { id: 5, descricao: TEMPO_NOTIFICACAO_LABELS.notificar5MinutosAntes },
  { id: 15, descricao: TEMPO_NOTIFICACAO_LABELS.notificar15MinutosAntes },
  { id: 30, descricao: TEMPO_NOTIFICACAO_LABELS.notificar30MinutosAntes },
  { id: 60, descricao: TEMPO_NOTIFICACAO_LABELS.notificar1HoraAntes },
];

function ModalCriarAtividade({ ids, callback = () => null, classes }) {
  const intl = useIntl();
  const history = useHistory();
  const [listaTipoAtividade, setListaTipoAtividade] = useState([]);

  const { data: tiposAtividade, isValidating: carregandoTiposAtividade } = useSWR("/api/pipeline/TipoAtividade/Listar");
  const { data: templatesEmail } = useSWR("/api/pipeline/TemplateEmail/BuscarSelecao");

  useEffect(
    () => {
      if (!carregandoTiposAtividade)
        setListaTipoAtividade(tiposAtividade.filter(x => x.nuTipoAtividade === NU_TIPO_ATIVIDADE_ID.ENVIAR_EMAIL));
    },
    [carregandoTiposAtividade]
  );

  const onSubmit = values => {
    AjaxBlackout.Show();
    axios
      .post("/api/pipeline/atividade/CriarEmMassa", { ...values, leadIds: ids, data: `${values.dia}T${values.horas}` })
      .then(resp => {
        if (resp) {
          callback(resp);
          AjaxBlackout.Hide();
          createDialogConfirm({
            title: intl.formatMessage({ defaultMessage: "Atividade(s) criada(s)" }),
            text: intl.formatMessage({
              defaultMessage:
                "Sua(s) atividade(s) foram criadas com sucesso, você pode conferi-las na tela de atividades.",
            }),
            tamanhoMaximo: "sm",
            fullWidth: true,
            actions: unmount => (
              <DialogActions>
                <Button
                  onClick={() => {
                    unmount();
                    callback(true);
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Fechar" })}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    unmount();
                    history.push({
                      pathname: "/spotter/atividades",
                      state: { filtroEnviarEmail: true },
                    });
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Ir para a tela de atividades" })}
                </Button>
              </DialogActions>
            ),
          });
        }
      })
      .catch(error => {
        console.error(error);
        createSnackbar(intl.formatMessage({ defaultMessage: "Operação falhou" }));
      })
      .finally(() => AjaxBlackout.Hide());
  };

  const validate = values => {
    const errors = {};
    if (values.tipoAtividadeId === 0)
      errors.tipoAtividadeId = intl.formatMessage({ defaultMessage: "*Campo obrigatório" });
    if (values.dia === "") errors.dia = intl.formatMessage({ defaultMessage: "*Campo obrigatório" });
    if (values.horas === "") errors.horas = intl.formatMessage({ defaultMessage: "*Campo obrigatório" });
    if (values.notificarId === null) errors.notificarId = intl.formatMessage({ defaultMessage: "*Campo obrigatório" });

    return errors;
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit} onReset={() => callback(false)} validate={validate}>
      {bag => (
        <Form>
          <DialogTitle>{intl.formatMessage({ defaultMessage: "Criar atividade em massa" })}</DialogTitle>
          <DialogContent>
            <Flex flexDirection="column">
              <Flex id="container-tipo-atividade" flexDirection="row">
                <Flex id="tipo-atividade" alignItems="baseline" className={classes.container}>
                  <Flex className={classes.icon}>
                    <AssignmentTurnedInIcon />
                  </Flex>
                  <Flex className={classes.container}>
                    <FormikAsyncSelect
                      id="tipoAtividadeId"
                      name="tipoAtividadeId"
                      label={intl.formatMessage({
                        defaultMessage: "Tipo de atividade",
                      })}
                      options={listaTipoAtividade}
                      enableSearch
                      fullWidth
                      carregarSomenteAoAbrir
                      required
                    />
                  </Flex>
                </Flex>
              </Flex>
              {bag.values.tipoAtividadeId ===
                tiposAtividade.find(x => x.nuTipoAtividade === NU_TIPO_ATIVIDADE_ID.ENVIAR_EMAIL).id && (
                <Flex id="container-template-email" flexDirection="row">
                  <Flex id="template-email" alignItems="baseline" className={classes.container}>
                    <Flex className={classes.icon}>
                      <MailIcon />
                    </Flex>
                    <Flex className={classes.container}>
                      <FormikAsyncSelect
                        id="templateEmailId"
                        name="templateEmailId"
                        label={intl.formatMessage({
                          defaultMessage: "Template de e-mail",
                        })}
                        options={templatesEmail}
                        getLabel={item => item.nome}
                        getInputLabel={item => item.nome}
                        enableSearch
                        fullWidth
                        carregarSomenteAoAbrir
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
              <Flex id="container-data-hora" flexDirection="row" justifyContent="space-between">
                <Flex id="data" alignItems="baseline" className={classes.container}>
                  <Flex className={classes.icon}>
                    <DateRangeIcon />
                  </Flex>
                  <Flex className={cn(classes.container, classes.espaco)}>
                    <Field
                      component={TextField}
                      name="dia"
                      id="dia"
                      fullWidth
                      inputProps={{ type: "date", min: new Moment().format("YYYY-MM-DD") }}
                      InputLabelProps={{ shrink: true }}
                      label={intl.formatMessage({ defaultMessage: "Dia" })}
                      required
                    />
                  </Flex>
                </Flex>
                <Flex id="hora" alignItems="baseline" className={classes.container}>
                  <Flex className={classes.icon}>
                    <ScheduleIcon />
                  </Flex>
                  <Flex className={classes.container}>
                    <Field
                      component={TextField}
                      name="horas"
                      id="horas"
                      fullWidth
                      inputProps={{ type: "time" }}
                      InputLabelProps={{ shrink: true }}
                      label={intl.formatMessage({ defaultMessage: "Horas" })}
                      required
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex id="container-notificar" flexDirection="row">
                <Flex id="notificar" alignItems="baseline" className={classes.container}>
                  <Flex className={classes.icon}>
                    <NotificationsIcon />
                  </Flex>
                  <Flex className={classes.container}>
                    <FormikAsyncSelect
                      id="notificarId"
                      name="notificarId"
                      label={intl.formatMessage({ defaultMessage: "Notificar" })}
                      getLabel={item => intl.formatMessage(item.descricao)}
                      getInputLabel={item => intl.formatMessage(item.descricao)}
                      options={TEMPO_NOTIFICACAO}
                      enableSearch
                      fullWidth
                      required
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex id="container-observacoes" flexDirection="row">
                <Flex id="observacoes" alignItems="baseline" className={classes.container}>
                  <Flex className={classes.icon}>
                    <SpeakerNotesIcon />
                  </Flex>
                  <Flex className={classes.container}>
                    <Field
                      name="observacoes"
                      component={TextField}
                      label={intl.formatMessage({ defaultMessage: "Observações" })}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Faça suas observações",
                      })}
                      multiline
                      fullWidth
                      value={bag.values.observacoes}
                      rows={4}
                      rowsMax={4}
                      inputProps={{ maxLength: 2000 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </DialogContent>
          <DialogActions>
            <Button onClick={bag.handleReset}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button onClick={bag.handleSubmit} color="primary">
              {intl.formatMessage({ defaultMessage: "Salvar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

ModalCriarAtividade.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  callback: PropTypes.func,
};

export default withStyles({
  container: {
    width: "100%",
  },
  espaco: {
    marginRight: 16,
  },
  icon: {
    marginRight: 8,
    color: "rgb(102, 102, 102)",
  },
})(ModalCriarAtividade);
