import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Dialog, Tooltip } from "@material-ui/core";
import ThumbDown from "@material-ui/icons/ThumbDown";

import { useUsuario, useSelectPermission } from "../../../../hooks";
import ModalCriarAtividade from "./ModalCriarAtividade";
import { ActionButton } from "../../../../components";
import PERMISSIONS from "../../../../_common/permissions";
import { ACCESS_LEVEL } from "../../../../_common/constantes";
import { createSnackbar } from "../../../../_common";

const LIMITE_LEADS = 100;

CriarAtividade.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  filtros: PropTypes.object,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      situacao: PropTypes.number,
      etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
    })
  ),
};

function CriarAtividade({ callback = x => x, children, leads = [], filtros, ...props }) {
  const intl = useIntl();
  const { id: usuarioId, isGerente, isPrevendedor, isVendedor, featureToggles } = useUsuario();
  const permissaoAtividade = useSelectPermission(PERMISSIONS.MODULOATIVIDADES);
  const [modalOpen, setModalOpen] = useState(false);
  const [textoTooltip, setTextoTooltip] = useState("");
  const [podeInteragirModal, setPodeInteragirModal] = useState(true);

  if (
    !featureToggles.rolloutNovaTelaAtividades ||
    !permissaoAtividade ||
    permissaoAtividade.nivelAcesso === ACCESS_LEVEL.NONE
  )
    return null;

  const validarPermissao = () => {
    setTextoTooltip("");
    setPodeInteragirModal(true);
    if (!isGerente && (permissaoAtividade && permissaoAtividade.nivelAcesso === ACCESS_LEVEL.INDIVIDUAL)) {
      if (
        (isPrevendedor && leads.find(i => i.preVendedorId !== usuarioId)) ||
        (isVendedor && leads.find(i => i.vendedorId !== usuarioId))
      ) {
        setPodeInteragirModal(false);
        setTextoTooltip(
          intl.formatMessage({
            defaultMessage: "Você não tem permissão para criar atividades de leads que não são seus",
          })
        );
      }
    }
  };

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  const abrirModal = () => {
    if (podeInteragirModal) {
      if (leads.length <= LIMITE_LEADS) setModalOpen(true);
      else
        createSnackbar(
          intl.formatMessage(
            { defaultMessage: `Selecione no máximo {limite} Leads para criar atividade` },
            { limite: LIMITE_LEADS }
          )
        );
    }
  };

  useEffect(
    () => {
      validarPermissao();
    },
    [leads]
  );

  return (
    <>
      {children ? (
        children({ setModalOpen })
      ) : (
        <Tooltip placement="bottom" title={textoTooltip}>
          <ActionButton
            id="btn-criar-atividade"
            type="menu"
            label={intl.formatMessage({ defaultMessage: "Criar Atividade" })}
            icon={<ThumbDown />}
            onClick={abrirModal}
            leads={leads}
            {...props}
          />
        </Tooltip>
      )}
      <Dialog maxWidth="sm" fullWidth open={modalOpen} onClose={() => close(false)}>
        <ModalCriarAtividade ids={leads.map(lead => lead.id)} {...props} callback={close} filtros={filtros} />
      </Dialog>
    </>
  );
}

export default CriarAtividade;
