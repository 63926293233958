import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles, Card, CardHeader, CardContent, FormLabel, IconButton } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import PlayArrow from "@material-ui/icons/PlayArrow";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";

import { useIntl } from "react-intl";
import { usePermission } from "../../../hooks";
import { Tipografia, Flex, Loading } from "../../../_common";
import { SelectCicloLead } from "../../../components";
import permissions from "../../../_common/permissions";
import { TIPO_QUESTIONARIO } from "../../../_common/constantes";

function Funil({ id, classes, lead }) {
  const canReevaluateFeedback = usePermission(permissions.REAVALIAR_FEEDBACK, lead);
  const canReevaluateFilter = usePermission(permissions.REAVALIAR, lead);
  const canSeeAnswerFilter = usePermission(permissions.VISUALIZAR_RESPOSTAS, lead);
  const canSeeAnswerFeedback = usePermission(permissions.VISUALIZAR_RESPOSTAS_FEEDBACK, lead);

  const [ciclo, setCiclo] = useState();
  const intl = useIntl();
  function changeCiclo(c) {
    if (c !== ciclo) {
      setLoading(true);
      setHistorico([]);
    }
    setCiclo(c);
  }

  const [loading, setLoading] = useState(true);
  const [historico, setHistorico] = useState([]);

  useEffect(
    () => {
      if (ciclo)
        axios
          .get(`/api/pipeline/lead/funil?id=${id}&ciclo=${ciclo}`)
          .then(({ data }) => setHistorico(data))
          .finally(() => setLoading(false));
    },
    [ciclo]
  );

  return (
    <Card className={classes.root} id="card-Funil">
      <CardHeader
        className={classes.cardHeader}
        disableTypography
        title={
          <Tipografia tipo="tituloCard">{intl.formatMessage({ defaultMessage: "Histórico de Conversões" })}</Tipografia>
        }
        action={
          <Fragment>
            <FormLabel style={{ marginRight: 16 }}>{intl.formatMessage({ defaultMessage: "Ciclo" })}</FormLabel>
            <SelectCicloLead id={id} onChange={changeCiclo} />
          </Fragment>
        }
      />
      <CardContent>
        <Loading isLoading={loading}>
          {!historico.length && (
            <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
              {intl.formatMessage({ defaultMessage: "Não há histórico de funil" })}
            </Tipografia>
          )}

          {!!historico.length &&
            historico.map(({ qualificacao, reuniao, etapa }) => (
              <Flex
                id={`funil-qualificacao-${etapa.id}`}
                key={etapa.id}
                className={classes.container}
                justifyContent="space-between"
              >
                <Tipografia cor={etapa.data ? "darkPrimaryText" : "darkSecondaryText"} style={{ width: "33%" }}>
                  {etapa.nome}
                </Tipografia>
                <Tipografia style={{ width: "33%" }}>{moment(etapa.data).format("DD/MM/YYYY")}</Tipografia>
                <Tipografia style={{ width: "33%" }}>
                  {qualificacao && `${qualificacao.descricao} (${qualificacao.pontuacao}Pts)`}
                  {reuniao &&
                    `${intl.formatMessage({ defaultMessage: "Reunião" })}: ${moment(reuniao.data).format(
                      "DD/MM/YYYY HH:mm"
                    )}`}
                </Tipografia>

                <Flex className={classes.acoesFiltro} justifyContent="flex-end">
                  {qualificacao &&
                    qualificacao.podeReaplicarFiltro &&
                    ((qualificacao.tipoQuestionario === TIPO_QUESTIONARIO.FEEDBACK && canReevaluateFeedback) ||
                      (qualificacao.tipoQuestionario === TIPO_QUESTIONARIO.QUESTIONARIO_LEAD &&
                        canReevaluateFilter)) && (
                      <Link
                        to={
                          qualificacao.continuarNegociacao && qualificacao.reuniaoId
                            ? `/spotter/feedback/${qualificacao.reuniaoId}?reavaliar=true`
                            : `/spotter/questionario?id=${qualificacao.questionarioId}&leadId=${id}&etapaId=${
                                etapa.id
                              }&avaliacaoId=${qualificacao.avaliacaoId}&ciclo=${ciclo}`
                        }
                        id={`reavaliar-filtro-${etapa.id}`}
                      >
                        <IconButton size="small" className={classes.filtro} classes={{ root: classes.botaoFiltro }}>
                          <PlayArrow />
                        </IconButton>
                      </Link>
                    )}

                  {qualificacao &&
                    (canSeeAnswerFilter || canSeeAnswerFeedback) && (
                      <Link
                        to={`/spotter/questionario?id=${qualificacao.questionarioId}&leadId=${id}&etapaId=${
                          etapa.id
                        }&avaliacaoId=${qualificacao.avaliacaoId}&readOnly=true&ciclo=${ciclo}`}
                        id={`visualizar-respostas-${etapa.id}`}
                      >
                        <IconButton size="small" className={classes.filtro} classes={{ root: classes.botaoFiltro }}>
                          <Info />
                        </IconButton>
                      </Link>
                    )}
                </Flex>
              </Flex>
            ))}
        </Loading>
      </CardContent>
    </Card>
  );
}

Funil.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
  lead: PropTypes.object,
};

export default withStyles({
  root: {
    marginBottom: 20,
  },
  cardHeader: {
    backgroundColor: "#f7f7f7",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)",
    height: 56,
  },
  container: {
    padding: "8px 20px",
    "&:hover": { backgroundColor: "rgb(247,247,247)" },
    "&:hover $filtro": { visibility: "visible" },
  },
  filtro: { visibility: "hidden" },
  botaoFiltro: { padding: 0 },
  acoesFiltro: {
    width: 48,
  },
})(Funil);
