import React, { useState } from "react";
import useSWR from "swr";
import { Formik, Form } from "formik";
import { defineMessage, useIntl } from "react-intl";
import { Button, DialogActions, DialogContent, Typography, DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";
import * as Yup from "yup";
import AsyncSelect from "../../../../components/AsyncSelect";

ModalEscolherEtapa.propTypes = {
  callback: PropTypes.func,
  onClose: PropTypes.func,
};

const CAMPO_OBRIGATORIO = defineMessage({ defaultMessage: "Obrigatório" });

export default function ModalEscolherEtapa({ callback, onClose }) {
  const intl = useIntl();
  const [etapa, setEtapa] = useState(0);
  const { data: etapas } = useSWR("/api/pipeline//Etapa/ListarEtapasPularLote");

  const setValor = async value => {
    setEtapa(Number(value));
  };

  return (
    <Formik
      initialValues={{ etapaId: 0 }}
      validationSchema={Yup.object().shape({
        etapaId: Yup.number().required(intl.formatMessage(CAMPO_OBRIGATORIO)),
      })}
      onSubmit={values => callback(values)}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <DialogTitle>{intl.formatMessage({ defaultMessage: "Selecione uma etapa de destino" })}</DialogTitle>
          <DialogContent>
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "A etapa anterior do Lead está inativa ou excluída. Para continuar, selecione uma etapa de destino para o Lead.",
              })}
            </Typography>
            <AsyncSelect
              getId={item => item.id}
              getLabel={item => item.descricao}
              id="field-distribuicao-listaFiltro"
              label={intl.formatMessage({
                defaultMessage: "Etapa",
              })}
              options={etapas}
              fullWidth
              value={etapa}
              onChange={e => {
                setValor(e.target.value);
                setFieldValue("etapaId", Number(e.target.value));
              }}
              required
              enableSearch
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {intl.formatMessage({ defaultMessage: "Confirmar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
