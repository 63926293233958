import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import MoneyOff from "@material-ui/icons/MoneyOff";
import { useIntl } from "react-intl";
import ModalDesfazerVenda from "./ModalDesfazerVenda";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { useUsuario } from "../../../../hooks";
import { PERMISSAO_ETAPA } from "../../../../_common/constantes";
import usePermissaoEtapa from "../../../base-leads/pipeline/components/usePermissaoEtapa";

DesfazerVenda.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    situacao: PropTypes.number,
    etapa: PropTypes.shape({
      posVenda: PropTypes.bool,
      id: PropTypes.number.isRequired,
      funilId: PropTypes.number,
      funil: PropTypes.object,
    }),
  }),
};

function DesfazerVenda({ id, callback = () => null, lead = {}, ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const permissaoNaEtapa = usePermissaoEtapa(lead.etapa);
  const { isGerente } = useUsuario();
  const hasPermission = isGerente || permissaoNaEtapa === PERMISSAO_ETAPA.INTERAGIR;

  const close = (success, data) => {
    setModalOpen(false);
    callback(success, data);
  };

  if (!hasPermission || !lead.etapa?.posVenda || !lead.situacao) return null;

  return (
    <Fragment>
      <ActionButton
        id="btnDesfazerVenda"
        type="menu"
        icon={<MoneyOff />}
        label={intl.formatMessage({ defaultMessage: "Desfazer venda" })}
        onClick={() => setModalOpen(true)}
        tooltip={intl.formatMessage({ defaultMessage: "Desfazer venda" })}
        claim={permissions.MARCAR_VENDIDO}
        leads={lead}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)}>
        <ModalDesfazerVenda id={id || lead.id} {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}

export default DesfazerVenda;
