import React from "react";
import PropTypes from "prop-types";
import Event from "@material-ui/icons/Event";
import WatchLater from "@material-ui/icons/WatchLater";
import { useIntl, defineMessages } from "react-intl";

import concluirAgendamento from "../concluirAgendamento";
import { ActionButton } from "../../../../components";
import useAgendarReuniao from "./useAgendarReuniao";
import { SITUACAO_REUNIAO, CONCLUIR_REUNIAO } from "../../../../_common/constantes";

AgendarReuniao.propTypes = {
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  reagendar: PropTypes.bool,
  redirecionarBaseLeads: PropTypes.bool,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    situacao: PropTypes.number,
    dataDescarte: PropTypes.string,
    agendamento: PropTypes.shape({ id: PropTypes.number, situacao: PropTypes.number }),
    reuniao: PropTypes.shape({ id: PropTypes.number, situacao: PropTypes.number }),
    etapa: PropTypes.shape({
      id: PropTypes.number.isRequired,
      tipoGate: PropTypes.number,
      proximaEtapaId: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

const messages = defineMessages({
  agendar: { defaultMessage: "Agendar Reunião" },
  reagendar: { defaultMessage: "Reagendar Reunião" },
  titleConcluir: { defaultMessage: "Concluir reunião" },
  textConcluir: {
    defaultMessage: "Para realizar o próximo agendamento é necessário concluir a reunião atual. Deseja fazer isso?",
  },
});

function AgendarReuniao({ claim, lead = {}, reagendar = false, redirecionarBaseLeads = false, ...props }) {
  const intl = useIntl();
  const { hasPermission, redirecionar, reuniaoId, defaultClaim } = useAgendarReuniao(
    lead,
    reagendar,
    redirecionarBaseLeads
  );

  if (!hasPermission) return null;

  return (
    <ActionButton
      claim={claim ?? defaultClaim}
      leads={lead}
      id={reagendar ? `btn-reagendar-reuniao_${lead.id}` : `btn-agendar-reuniao_${lead.id}`}
      type="icon"
      onClick={
        !reagendar &&
        reuniaoId &&
        (lead.agendamento?.situacao === SITUACAO_REUNIAO.VIGENTE || lead.reuniao?.situacao === SITUACAO_REUNIAO.VIGENTE)
          ? () =>
              concluirAgendamento(
                reuniaoId,
                lead.id,
                CONCLUIR_REUNIAO.AGENDAMENTO,
                intl.formatMessage({
                  defaultMessage:
                    "Para realizar o próximo agendamento é necessário concluir a reunião atual. Deseja continuar?",
                }),
                redirecionar
              )
          : redirecionar
      }
      icon={reagendar ? <WatchLater /> : <Event />}
      tooltip={intl.formatMessage(reagendar ? messages.reagendar : messages.agendar)}
      label={intl.formatMessage(reagendar ? messages.reagendar : messages.agendar)}
      {...props}
    />
  );
}

export default AgendarReuniao;
