import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { v4 } from "uuid";
import Email from "@material-ui/icons/Email";
import SendIcon from "@material-ui/icons/Send";

import ModalNovoEmail from "../../../central-emails/ModalNovoEmail/ModalNovoEmail";
import ModalLerEmail from "../../../central-emails/ModalLerEmail/ModalLerEmail";
import ModalVisualizarEmail from "../../../central-emails/ModalVisualizarEmail/ModalVisualizarEmail";
import {
  adicionarEmailTo,
  abrirEscreverEmail,
  getTextChipContato,
  setParametrosEmail,
} from "../../../../store/reducers/emails/emailsActions";
import { ActionButton } from "../../../../components";
import { useUsuario } from "../../../../hooks";

function EnviarEmail({
  podeEnviarEmail,
  adicionarEmail,
  escreverEmail,
  lead,
  useIconMenu,
  setParametrosLeadIdSelecionado,
  useJustIcon = false,
  ...props
}) {
  const intl = useIntl();
  const { isPVOrVendedor } = useUsuario();

  if (!useIconMenu && (!podeEnviarEmail || lead?.contatos.every(contato => !contato.email))) return null;

  function openEmail() {
    if (isPVOrVendedor && !podeEnviarEmail) return this.props.history.push("/spotter/central-emails");

    const contatoPrincipal = lead?.contatos?.find(c => c.principal);
    const contato = contatoPrincipal && contatoPrincipal.email ? contatoPrincipal : lead?.contatos?.find(c => c.email);

    if (contato && contato.email) {
      setParametrosLeadIdSelecionado({ leadIdSelecionado: lead?.id, emailSelecionado: contato.email });

      const { nome, email, cargo } = contato;
      adicionarEmail({
        id: v4(),
        cargo,
        name: nome,
        email,
        text: getTextChipContato(nome, email, lead?.nome),
        listaLeads: [{ nome: lead?.nome, id: lead?.id }],
      });
    }

    return escreverEmail();
  }

  return (
    <Fragment>
      {useIconMenu ? (
        <ActionButton
          id="btn-enviar-email-atividade"
          type={useJustIcon ? "icon" : "menu"}
          onClick={openEmail}
          icon={useJustIcon ? <SendIcon /> : <Email />}
          label={!useJustIcon ? intl.formatMessage({ defaultMessage: "Enviar e-mail" }) : null}
          disabled={!podeEnviarEmail}
          {...props}
        />
      ) : (
        <ActionButton id="btn-enviar-email" color="primary" type="text" label="E-mail" onClick={openEmail} {...props} />
      )}

      <ModalLerEmail podeEnviarEmail={podeEnviarEmail} />
      <ModalVisualizarEmail />
      <ModalNovoEmail />
    </Fragment>
  );
}

EnviarEmail.propTypes = {
  children: PropTypes.func,
  podeEnviarEmail: PropTypes.bool,
  contatos: PropTypes.array,
  adicionarEmail: PropTypes.func,
  escreverEmail: PropTypes.func,
  setParametrosLeadIdSelecionado: PropTypes.func,
  useIconMenu: PropTypes.bool,
  useJustIcon: PropTypes.bool,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    nome: PropTypes.string.isRequired,
    contatos: PropTypes.arrayOf(
      PropTypes.shape({
        principal: PropTypes.bool,
        nome: PropTypes.string,
        cargo: PropTypes.string,
        email: PropTypes.string,
      })
    ).isRequired,
  }),
};

const mapStateToProps = state => ({
  podeEnviarEmail: state.emails && state.emails?.isAutenticado && !state.emails?.isAutenticacaoComFalha,
});

const mapDispatchToProps = dispatch => ({
  adicionarEmail: (...args) => dispatch(adicionarEmailTo(...args)),
  escreverEmail: (...args) => dispatch(abrirEscreverEmail(...args)),
  setParametrosLeadIdSelecionado: parametros => dispatch(setParametrosEmail(parametros)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnviarEmail);
