import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";

import { AjaxBlackout, createDialogConfirm } from "../../../../_common";
import { FormikSelectMotivoDescarte } from "../../../../components/SelectMotivoDescarte";
import { maybeCallback } from "../../../../_common/utils/fp";
import { useTipoPlanoSpotter } from "../../../../hooks";

const QTD_MIN_EXCLUIR_EM_LOTE = 10;
function ModalDescartar({ ids, callback = () => null, filtros }) {
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const { enqueueSnackbar } = useSnackbar();
  const leads = ids.length;

  const excluirEmLote = leads >= QTD_MIN_EXCLUIR_EM_LOTE;

  function validarDescarte(value) {
    if (value !== leads.toString() && value >= QTD_MIN_EXCLUIR_EM_LOTE) {
      return intl.formatMessage({ defaultMessage: "Informe o valor certo de leads a ser excluido" });
    }
    return "";
  }

  function confirmarDescarte({ motivoId, observacoes }) {
    createDialogConfirm({
      title: intl.formatMessage(
        { defaultMessage: "Você descartará {quantidade} leads, deseja continuar?" },
        { quantidade: leads }
      ),
      text: intl.formatMessage({
        defaultMessage:
          "Todas as atividades e reuniões vinculadas aos leads serão canceladas. Para continuar, digite o número de leads que serão descartados:",
      }),
      maxWidth: "lg",
      actions: close => (
        <Formik
          initialValues={{ quantidadeLeads: "" }}
          onSubmit={() => descartar({ motivoId, observacoes }, close)}
          onReset={() => callback(false)}
        >
          {() => (
            <Form>
              <DialogContent style={{ display: "flex", flexDirection: "column" }}>
                <Field
                  component={TextField}
                  label={intl.formatMessage({
                    defaultMessage: "Confirme a quantidade de leads a serem descartados:",
                  })}
                  name="quantidadeLeads"
                  required
                  validate={validarDescarte}
                  InputLabelProps={{ style: { fontSize: 13 } }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={close}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
                <Button color="primary" type="submit">
                  {intl.formatMessage({ defaultMessage: "Confirmar descarte de leads" })}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      ),
    });
  }

  async function descartar({ motivoId, observacoes }, close) {
    AjaxBlackout.Show();
    try {
      const { data } = await axios.post("/api/pipeline/lead/descartar", {
        ids,
        motivoId,
        observacao: observacoes,
        filtros,
      });
      if (!data.success) throw Error("Ocorreu um erro");

      if (data.exibeMensagemProcessamento) {
        createDialogConfirm({
          title: intl.formatMessage({ defaultMessage: "Descarte de leads" }),
          text: intl.formatMessage({
            defaultMessage:
              "Sua solicitação está em processamento e assim que concluída você será notificado por e-mail.",
          }),
          maxWidth: "sm",
          fullWidth: true,
          actions: unmount => (
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  unmount();
                  callback(true);
                }}
              >
                {intl.formatMessage({ defaultMessage: "OK" })}
              </Button>
            </DialogActions>
          ),
        });
      } else {
        enqueueSnackbar(intl.formatMessage({ defaultMessage: "Operação realizada com sucesso" }), {
          variant: "info",
        });
        data.mensagensIntegracao.forEach(message => enqueueSnackbar(message, { variant: "info" }));
        maybeCallback(close)();
        callback(true);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "info" });
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  return (
    <Formik
      initialValues={{ motivoId: "", observacoes: "" }}
      onSubmit={excluirEmLote ? confirmarDescarte : descartar}
      onReset={() => callback(false)}
    >
      {bag => (
        <Form>
          <DialogTitle>{intl.formatMessage({ defaultMessage: "Descartar Lead" })}</DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage:
                  "Ao descartar esse lead ele não será mais mostrado no funil, porém seus dados serão mantidos no sistema e será possível resgatá-lo futuramente.",
              })}
            </DialogContentText>
            <FormikSelectMotivoDescarte
              validate={v => (!v ? intl.formatMessage({ defaultMessage: "Campo obrigatório *" }) : undefined)}
              label={intl.formatMessage({ defaultMessage: "Defina o motivo do descarte *" })}
            />

            {isSpotterFull && (
              <Field
                component={TextField}
                label={intl.formatMessage({ defaultMessage: "Observações" })}
                multiline
                rows={4}
                name="observacoes"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={bag.handleReset}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button onClick={bag.handleSubmit} color="primary">
              {intl.formatMessage({ defaultMessage: "Confirmar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

ModalDescartar.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  filtros: PropTypes.object,
  callback: PropTypes.func,
};

export default ModalDescartar;
