import React from "react";
import { Formik, Form, Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import { useIntl } from "react-intl";
import { Button, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { AjaxBlackout, createSnackbarAPIException } from "../../../../_common";
import { FormikAsyncSelect } from "../../../../components/AsyncSelect";

ModalAlterarTipoReuniao.propTypes = {
  leadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
  onClose: PropTypes.func,
  tipoAtual: PropTypes.number,
  reuniaoId: PropTypes.number,
};

export default function ModalAlterarTipoReuniao({ leadId, tipoAtual, reuniaoId, callback, onClose }) {
  const intl = useIntl();
  const tipoReuniao = [
    { id: 1, descricao: intl.formatMessage({ defaultMessage: "Presencial" }) },
    { id: 2, descricao: intl.formatMessage({ defaultMessage: "Online" }) },
    { id: 3, descricao: intl.formatMessage({ defaultMessage: "Por telefone" }) },
  ];

  async function onSave(values) {
    try {
      AjaxBlackout.Show();
      const { data: success } = await axios.post(`/api/pipeline/Reuniao/AlterarTipo`, {
        ...values,
        leadId,
        tipoAtualReuniao: tipoAtual,
        reuniaoId,
      });
      callback(success);
      onClose();
    } catch (e) {
      createSnackbarAPIException(e);
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  const validate = values => {
    const errors = {};

    if (values.tipoNovoReuniao === tipoAtual)
      errors.tipoNovoReuniao = intl.formatMessage({
        defaultMessage: "O tipo informado deve ser diferente do atual",
      });

    return errors;
  };

  return (
    <Formik
      initialValues={{ tipoNovoReuniao: tipoAtual, reenviarDiagnostico: true }}
      onSubmit={onSave}
      validate={validate}
    >
      <Form>
        <DialogTitle>{intl.formatMessage({ defaultMessage: "Alterar tipo de reunião" })}</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <FormikAsyncSelect
            name="tipoNovoReuniao"
            label={intl.formatMessage({ defaultMessage: "Selecione o tipo de reunião" })}
            id="alterar-tipo-reuniao"
            options={tipoReuniao}
            fullWidth
            enableSearch
          />
          <Field
            name="reenviarDiagnostico"
            id="reenviar-diagnostico"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{ label: intl.formatMessage({ defaultMessage: "Reenviar diagnóstico" }) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}> {intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
          <Button type="submit" color="primary">
            {intl.formatMessage({ defaultMessage: "Confirmar" })}
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
}
