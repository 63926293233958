import React, { useState } from "react";
import useSWR from "swr";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { defineMessage, useIntl } from "react-intl";
import { Button, DialogTitle, DialogContent, DialogActions, Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import * as Yup from "yup";

import { AjaxBlackout, createSnackbarAPIException } from "../../../../_common";
import { FormikSelectMotivoReuniao } from "../../../../components/SelectMotivoReuniao";
import ModalEscolherEtapa from "./ModalEscolherEtapa";

ModalCancelarReuniao.propTypes = {
  leadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
  onClose: PropTypes.func,
};

const CAMPO_OBRIGATORIO = defineMessage({ defaultMessage: "Obrigatório" });

export default function ModalCancelarReuniao({ leadId, callback, onClose }) {
  const intl = useIntl();
  const [abrirModelEtapa, setAbrirModalEtapa] = useState(false);
  const [valoresFormulario, setValoresFormulario] = useState();
  const { data: motivosReunioesNaoOcorridas } = useSWR("/api/pipeline/MotivoReuniao/Listar");
  const { data: etapaAtiva } = useSWR(`/api/pipeline/Reuniao/VerificaCancelar?leadId=${leadId}`);

  const CancelarReuniao = async (values, etapa) => {
    AjaxBlackout.Show();
    try {
      const postData = {
        observacoes: values.observacoes,
        leadId,
        motivoId: Number(values.motivoId),
        etapaId: null,
      };

      if (!etapaAtiva) {
        postData.etapaId = Number(etapa.etapaId);
      }
      const { data: success } = await axios.post(`/api/pipeline/Reuniao/Cancelar`, postData);
      callback(success);
      onClose();
    } catch (e) {
      createSnackbarAPIException(e);
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  };

  const selecionarEtapa = etapa => {
    CancelarReuniao(valoresFormulario, etapa);
  };

  if (!motivosReunioesNaoOcorridas) return null;
  return (
    <>
      <Formik
        initialValues={{ motivoId: 0, observacoes: "" }}
        validationSchema={Yup.object().shape({
          motivoId: Yup.number()
            .moreThan(0, intl.formatMessage(CAMPO_OBRIGATORIO))
            .required(intl.formatMessage(CAMPO_OBRIGATORIO)),
        })}
        onSubmit={async values => {
          if (!etapaAtiva) {
            setAbrirModalEtapa(true);
            setValoresFormulario(values);
          } else {
            CancelarReuniao(values);
          }
        }}
      >
        <Form>
          <DialogTitle>{intl.formatMessage({ defaultMessage: "Motivo do cancelamento" })}</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <FormikSelectMotivoReuniao name="motivoId" fullWidth />
            <Field
              fullWidth
              multiline
              rows="3"
              name="observacoes"
              component={TextField}
              label={intl.formatMessage({ defaultMessage: "Observações" })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}> {intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button type="submit" color="primary">
              {intl.formatMessage({ defaultMessage: "Aplicar" })}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
      <Dialog maxWidth="sm" fullWidth open={abrirModelEtapa}>
        <ModalEscolherEtapa callback={selecionarEtapa} onClose={() => setAbrirModalEtapa(false)} />
      </Dialog>
    </>
  );
}
