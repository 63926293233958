import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";
import { createSnackbar, createDialogConfirm, AjaxBlackout } from "../../../../_common";

function ModalExcluirVariosConfirmacao({ ids, callback = () => null }) {
  const intl = useIntl();
  const leads = ids.length;
  function validarexclusao(value) {
    if (value !== leads.toString()) {
      return intl.formatMessage({ defaultMessage: "Informe o valor certo de leads a ser excluído" });
    }
    return "";
  }

  async function excluir(value) {
    AjaxBlackout.Show();
    try {
      const { data } = await axios.post(
        `/api/pipeline/lead/excluir?confirmacaoQtdeLeads=${value.quantidadeLeads}`,
        ids
      );

      if (!data.success) throw Error("Ocorreu um erro");

      if (data.exibeMensagemProcessamento) {
        createDialogConfirm({
          title: intl.formatMessage({ defaultMessage: "Exclusão de leads" }),
          text: intl.formatMessage({
            defaultMessage:
              "Sua solicitação está em processamento e assim que concluída você será notificado por e-mail.",
          }),
          maxWidth: "sm",
          fullWidth: true,
          actions: unmount => (
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  unmount();
                  callback(true);
                }}
              >
                OK
              </Button>
            </DialogActions>
          ),
        });
      } else {
        callback(true);
      }
    } catch (error) {
      createSnackbar(error.message);
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  return (
    <Formik initialValues={{ quantidadeLeads: "" }} onSubmit={excluir} onReset={() => callback(false)}>
      {bag => (
        <Form noValidate>
          <DialogTitle>
            {intl.formatMessage(
              {
                defaultMessage:
                  "Você está excluindo permanentemente {quantidade} leads, incluindo leads de outros usuários. Tem certeza?",
              },
              { quantidade: leads }
            )}
          </DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage:
                  "Esta ação afetará a base de outros usuários, não só a sua. Após a confirmação a ação será <b>irreversível</b>. Os dados selecionados não poderão ser restaurados por questões de segurança da informação.",
              })}
            </DialogContentText>
            <DialogContentText>
              {intl.formatMessage({
                defaultMessage: "Para continuar, digite o número de leads que serão excluídos:",
              })}
            </DialogContentText>
            <Field
              component={TextField}
              label={intl.formatMessage({
                defaultMessage: "Confirme a quantidade de leads a serem apagados",
              })}
              name="quantidadeLeads"
              required
              validate={validarexclusao}
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={bag.handleReset}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
            <Button color="primary" type="submit">
              {intl.formatMessage({ defaultMessage: "Confirmar exclusão de leads" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

ModalExcluirVariosConfirmacao.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  callback: PropTypes.func,
};

export default ModalExcluirVariosConfirmacao;
