import React from "react";
import useSWR from "swr";
import PropTypes from "prop-types";
import { Button, DialogContent, DialogActions, Dialog, DialogTitle, withStyles, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import moment from "moment";
import { Flex, InfoItem } from "../../../../_common";
import { cnpjMask } from "../../../../components/TextMaskCpfCnpj";
import { toTitleCase } from "../../../../_common/utils/string";
import { TIPO_FUNCIONALIDADE } from "../../../../_common/constantes";

export function ConsultarSearching({ classes, open, onClose, cnpj }) {
  const intl = useIntl();

  const { data: possuiSearching } = useSWR(
    `/api/pipeline/funcionalidadeplanoempresa/temfuncionalidade?tipofuncionalidade=${
      TIPO_FUNCIONALIDADE.SEARCHING
    }&validartrial=true`
  );
  const { data } = useSWR(possuiSearching ? `/api/pipeline/lead/consultarsearching?cnpj=${cnpj}` : null);

  return (
    <>
      <Dialog maxWidth={possuiSearching ? "md" : "sm"} fullWidth open={open} onClose={onClose}>
        <DialogTitle>{intl.formatMessage({ defaultMessage: "Consulta de CNPJ" })}</DialogTitle>
        <DialogContent>
          {possuiSearching ? (
            <>
              <Typography className={classes.texto}>
                {intl.formatMessage({ defaultMessage: "Informações coletadas pelo Searching." })}
              </Typography>
              <br />
              <Flex className={classes.flexContainer} justifyContent="space-between">
                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Nome Fantasia" })}
                  value={data?.result?.data?.nomeFantasia ? data?.result?.data?.nomeFantasia : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Situação" })}
                  value={data?.result?.data?.situacao ? toTitleCase(data?.result?.data?.situacao) : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Razão social" })}
                  value={data?.result?.data?.razaoSocial ? data?.result?.data?.razaoSocial : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Telefone 1" })}
                  value={data?.result?.data?.telefone1 ? data?.result?.data?.telefone1 : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "CNPJ" })}
                  value={data?.result?.data?.cnpj ? cnpjMask(data?.result?.data?.cnpj) : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Telefone 2" })}
                  value={data?.result?.data?.telefone2 ? data?.result?.data?.telefone2 : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Porte" })}
                  value={data?.result?.data?.porteTipo ? data?.result?.data?.porteTipo : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "E-mail" })}
                  value={data?.result?.data?.email ? data?.result?.data?.email : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Faturamento" })}
                  value={data?.result?.data?.faixaFaturamento ? data?.result?.data?.faixaFaturamento : "-"}
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Endereço" })}
                  value={
                    data?.result?.data?.logradouro
                      ? `${toTitleCase(data?.result?.data?.logradouro)}, ${
                          data?.result?.data?.numero
                        }\n - \n${toTitleCase(data?.result?.data?.municipio)}/${data?.result?.data?.uf}\n- ${
                          data?.result?.data?.cep
                        }`
                      : "-"
                  }
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Data de abertura" })}
                  value={
                    data?.result?.data?.dataAbertura
                      ? moment(data?.result?.data?.dataAbertura).format("DD/MM/YYYY")
                      : "-"
                  }
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Sócios" })}
                  value={
                    data?.result?.data?.socio
                      ? JSON.parse(`[${data?.result?.data?.socio.replace(/\\"/g, '"').slice(1, -1)}]`)
                          .map(s => toTitleCase(s))
                          .join(", ")
                      : "-"
                  }
                />
              </Flex>
            </>
          ) : (
            <Typography className={classes.texto}>
              {intl.formatMessage({
                defaultMessage:
                  "Assine o Searching para ver essas informações. Entre em contato com a equipe de Atendimento.",
              })}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{intl.formatMessage({ defaultMessage: "Fechar" })}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConsultarSearching.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  cnpj: PropTypes.string,
};

const styles = theme => ({
  campo: { width: "50% !important" },
  flexContainer: { marginLeft: -20, flexWrap: "wrap" },
  texto: {
    color: theme.palette.text.secondary,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "0.15px",
  },
});

export default withStyles(styles)(ConsultarSearching);
