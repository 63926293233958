import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Dialog } from "@material-ui/core";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ModalDescartar from "./ModalDescartar";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";

Descartar.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  filtros: PropTypes.object,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
      situacao: PropTypes.number,
      etapa: PropTypes.shape({ posVenda: PropTypes.bool }),
    })
  ),
};

function vendidoOuDescartado(lead) {
  return (lead.etapa && lead.etapa.posVenda) || !lead.situacao;
}

function Descartar({ callback = x => x, children, leads = [], filtros, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);

  if (leads && leads.some(vendidoOuDescartado)) return null;

  const close = response => {
    setModalOpen(false);
    callback(response);
  };
  const intl = useIntl();
  return (
    <Fragment>
      {children ? (
        children({ setModalOpen })
      ) : (
        <ActionButton
          id="btn-descartar"
          type="menu"
          label={intl.formatMessage({ defaultMessage: "Descartar" })}
          icon={<ThumbDown />}
          onClick={() => setModalOpen(true)}
          claim={permissions.DESCARTAR}
          leads={leads}
          {...props}
        />
      )}
      <Dialog maxWidth="sm" fullWidth open={modalOpen} onClose={() => close(false)}>
        <ModalDescartar ids={leads.map(lead => lead.id)} {...props} callback={close} filtros={filtros} />
      </Dialog>
    </Fragment>
  );
}

export default Descartar;
