import React from "react";
import PropTypes from "prop-types";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { useIntl, defineMessage } from "react-intl";
import axios from "axios";

import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import createSnackbarAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import { SITUACAO_REUNIAO } from "../../../../_common/constantes";
import { AjaxBlackout } from "../../../../_common";

ConfirmarReuniao.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  callback: PropTypes.func,
  lead: PropTypes.shape({
    agendamento: PropTypes.object,
    reuniaoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    situacao: PropTypes.number,
    reuniao: PropTypes.object,
    dataDescarte: PropTypes.string,
    etapa: PropTypes.shape({
      tipoGate: PropTypes.number,
      gateAnterior: PropTypes.number,
      proximaEtapaId: PropTypes.number,
    }),
  }),
};

function ConfirmarReuniao({ id, lead = {}, callback, ...props }) {
  const reuniaoId = lead.reuniaoId ?? lead.reuniao?.id;
  if (
    (lead.dataDescarte && lead.situacao !== 1) ||
    !reuniaoId ||
    lead.reuniao?.confirmacao ||
    (lead.reuniao &&
      (lead.reuniao.situacao !== SITUACAO_REUNIAO.VIGENTE && lead.reuniao.situacao !== SITUACAO_REUNIAO.REAGENDADA))
  ) {
    return null;
  }

  const intl = useIntl();
  const confirmarMessage = defineMessage({
    defaultMessage: "Confirmar Reunião",
  });

  async function confirmar() {
    AjaxBlackout.Show();
    try {
      await axios.post(`/api/pipeline/reuniao/confirmar`, {
        id: reuniaoId,
        leadId: lead.id,
        confirmacao: true,
      });
      callback(true);
    } catch (err) {
      createSnackbarAPIException(err);
      callback(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  return (
    <>
      <ActionButton
        leads={lead}
        id={`btn-confirmar-reuniao_${lead.id}`}
        claim={permissions.SINALIZAR_REUNIAO_CONFIRMADA}
        type="icon"
        onClick={confirmar}
        icon={<EventAvailableIcon />}
        tooltip={intl.formatMessage(confirmarMessage)}
        label={intl.formatMessage(confirmarMessage)}
        {...props}
      />
    </>
  );
}

export default ConfirmarReuniao;
