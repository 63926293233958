import React from "react";
import PropTypes from "prop-types";
import LowPriority from "@material-ui/icons/LowPriority";
import { useIntl } from "react-intl";

import useDesfazerTransferirDeFunil from "./useDesfazerTransferirDeFunil";
import { ActionButton } from "../../../../components/ActionButton";

DesfazerTransferirDeFunil.propTypes = {
  lead: PropTypes.object,
  leadId: PropTypes.number,
  callback: PropTypes.func,
};
function DesfazerTransferirDeFunil(props) {
  const intl = useIntl();
  const [lead, handleClick] = useDesfazerTransferirDeFunil(props);

  if (lead?.conversao?.tipo !== 11) return null;
  const { callback, ...buttonProps } = props;

  return (
    <ActionButton
      onClick={handleClick}
      icon={<LowPriority style={{ transform: "rotate(90deg)" }} />}
      leads={lead}
      tooltip={intl.formatMessage({ defaultMessage: "Desfazer transferir de funil" })}
      label={intl.formatMessage({ defaultMessage: "Desfazer transferir de funil" })}
      {...buttonProps}
    />
  );
}

export default DesfazerTransferirDeFunil;
